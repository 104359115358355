import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Collapse,
  NavbarToggler,
  Button,
} from "reactstrap";
import { BiSearch } from "react-icons/bi";
import { MdNotificationsNone } from "react-icons/md";
import logo from "./logo.jpg";
import mon from "./nullProfile.png";
import {
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from "@knocklabs/react-notification-feed";
import "@knocklabs/react-notification-feed/dist/index.css";
import { useState, useRef, useEffect } from "react";
import { Card } from "reactstrap";
import { ENDPOINT_URL } from "../config/app-config";
import avatar from "./avatar.webp";

// const className = [
//     rnf-notification-icon-button == {
//         color: '#fff',
//     },
// ];

const NavBar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);
  const [username, setUser] = useState("");
  const [userData, setData] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    getUsername();

    getworkerData();
  }, []);

  const getUsername = () => {
    const user = localStorage.getItem("username");
    setUser(user);
  };

  const getworkerData = async () => {
    const wid = localStorage.getItem("worker_id");

    const dataw = localStorage.getItem("userdata");
    let dataww = JSON.parse(dataw);
    let id = "";
    if (dataww.worker_id == 0) {
      id = wid;
    } else {
      console.log("hi");
      id = dataww.worker_id;
    }

    try {
      var response = await fetch(`${ENDPOINT_URL}getaworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + dataww.token,
        },
        body: JSON.stringify({ worker_id: id }),
      });
      var data = await response.json();
      setName(`${data.result[0].fname} ${data.result[0].lname}`);
      setData(data.result[0].picture);
      localStorage.setItem("datuser", JSON.stringify(data.result[0]));
      console.log(data);
    } catch (err) {
      return err;
    }
  };

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar
        className="d-none d-lg-block"
        style={{ background: "#49C5B1", borderRadius: "0px 0px 20px 20px" }}
        fixed="top"
      >
        <NavbarBrand href="/home">
          <img
            alt="logo"
            src={logo}
            style={{
              height: "40px",
              width: "40px",
            }}
          />
        </NavbarBrand>
        <Nav className="me-auto">
          <NavItem>
            <NavLink style={{ color: "#FFF" }} href="/home">
              หน้าแรก
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: "#FFF" }} href="/jobFinder">
              ค้นหางาน
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: "#FFF" }} href="/detail">
              ประวัติการสมัครงาน
            </NavLink>
          </NavItem>
        </Nav>
        <Button color="danger" href="/" onClick={() => localStorage.clear()}>
          ออกจากระบบ
        </Button>

        {/* <MdNotificationsNone
                    style={{
                        width: '25px',
                        height: '25px',
                        color: '#FFF',
                    }}
                    ref={notifButtonRef}
                    onClick={(e) => setIsVisible(!isVisible)}
                >
                    <Card
                        buttonRef={notifButtonRef}
                        isVisible={isVisible}
                        onClose={() => setIsVisible(false)}
                        style={{ visibility: 'visible' }}
                    >555</Card>
                </MdNotificationsNone> */}

        {/* <KnockFeedProvider
                    apiKey={process.env.KNOCK_PUBLIC_API_KEY}
                    feedId={process.env.KNOCK_FEED_ID}
                >
                    <>
                        <NotificationIconButton
                            ref={notifButtonRef}
                            onClick={(e) => setIsVisible(!isVisible)}
                        />
                        <NotificationFeedPopover
                            buttonRef={notifButtonRef}
                            isVisible={isVisible}
                            onClose={() => setIsVisible(false)}
                        />
                    </>
                </KnockFeedProvider> */}
        {/* <MdNotificationsNone
                    style={{
                        width: '25px',
                        height: '25px',
                        color: '#FFF',
                        marginLeft: '15px'
                    }} /> */}
        <NavbarText style={{ marginLeft: "15px", color: "#FFF" }}>
          <p style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            {name === "" ? "ยังไม่ได้ลงทะเบียน" : name}
          </p>
          <p style={{ marginBottom: "-5px", fontSize: "13px" }}>หน้าโปรไฟล์</p>
        </NavbarText>
        <a href="/profile">
          <img
            src={avatar}
            style={{
              marginLeft: "10px",
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
            href="/profile"
          />
        </a>
      </Navbar>
      <Navbar
        fixed="top"
        className="d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"
        style={{ background: "#49C5B1", borderRadius: "0px 0px 20px 20px" }}
      >
        <NavbarBrand href="/" className="me-auto">
          <img
            alt="logo"
            src={logo}
            style={{
              height: "40px",
              width: "40px",
            }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink style={{ color: "#FFF" }} href="/home">
                หน้าแรก
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: "#FFF" }} href="/jobFinder">
                ค้นหางาน
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: "#FFF" }} href="/detail">
                ประวัติการสมัครงาน
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: "#FFF" }} href="/profile">
                โปรไฟล์
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: "#FFF" }} href="/">
                ออกจากระบบ
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
