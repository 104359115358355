import React from "react";
import {
  Card,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { ENDPOINT_URL } from "../../config/app-config";

const Forget = () => {
  const [phone, setPhone] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPass, setCheckPass] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [checkPassConfirm, setCheckPassConfirm] = useState(false);

  const handleChange = (event) => {
    const phone = event.target.value;
    setPhone(phone);
    localStorage.setItem("phone_reset", phone);
    if (phone.length === 10 && /[0-9]/.test(phone)) {
      setCheckPhone(false);
      // localStorage.setItem("username", phone);
    } else {
      setCheckPhone(true);
    }
  };
  const handlePass = (event) => {
    const password = event.target.value;
    setPassword(password);
    localStorage.setItem("password_reset", password);
    if (
      password.length >= 8 &&
      password.length <= 12 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      !/[]/.test(password) &&
      password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)
    ) {
      setCheckPass(false);
    } else {
      setCheckPass(true);
    }
  };
  const handlePassConfirm = (event) => {
    const passwordConfirm = event.target.value;
    setPasswordConfirm(passwordConfirm);
    if (passwordConfirm === password) {
      setCheckPassConfirm(false);
    } else {
      setCheckPassConfirm(true);
    }
  };

  const otpres = async () => {
    let body = {
      phone: phone,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otprequest`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      if ((result.status = "success")) {
        window.location = "/verification";
      }
      localStorage.setItem("token", result.token);
    } catch (err) {
      return err;
    }
  };

  //   const changePwd = async () => {
  //     const jwt = localStorage.getItem("jwt_token");
  //     let body = {
  //       phone: phone,
  //       new_password: password,
  //     };
  //     try {
  //       var response = await fetch(`${ENDPOINT_URL}/changpwd`, {
  //         method: "POST",
  //         headers: {
  //           Accept: "appclication/json",
  //           "Content-Type": "appclication/json",
  //           Authorization: "Bearer " + jwt,
  //         },
  //         body: JSON.stringify(body),
  //       });
  //     } catch (err) {
  //       return err;
  //     }
  //   };
  return (
    <div className="container">
      <Form inline>
        <Container className="d-flex vh-100">
          <Row className="m-auto align-self-center">
            <Col>
              <Card body className="my-2">
                <CardTitle align="center" tag="h2">
                  WorkWith
                </CardTitle>
                <FormGroup>
                  <Label>เบอร์โทรศัพท์</Label>
                  <Input
                    invalid={checkPhone}
                    value={phone || ""}
                    placeholder="Telphone number"
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>กรุณาตั้งรหัสผ่านใหม่</Label>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                    invalid={checkPass}
                    value={password || ""}
                    onChange={handlePass}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>ยืนยันรหัสผ่าน</Label>
                  <Input
                    name="passwordConfirm"
                    type="password"
                    placeholder="Password"
                    invalid={checkPassConfirm}
                    value={passwordConfirm || ""}
                    onChange={handlePassConfirm}
                  />
                </FormGroup>
                <Button
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  onClick={(e) => {
                    otpres();
                  }}
                >
                  ลืมรหัสผ่าน
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Forget;
