import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { ENDPOINT_URL } from "../../config/app-config";
import moment from "moment/moment";

const JobHistoryPage = () => {
  const [historys, setHis] = useState([]);
  useEffect(() => {
    gethistory();
  }, []);

  const gethistory = async () => {
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}workerhistorydetails`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ token: jwt }),
      });
      var data = await response.json();
      setHis(data.result);
    } catch (err) {}
  };

  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Card body style={{ marginBottom: "15px" }}>
        <h5 style={{ color: "#5E5873", fontWeight: "bold" }}>
          ประวัติการสมัครงาน
        </h5>
        {historys.map((his) => (
          <li key={his.announcement_id} style={{ listStyleType: "none" }}>
            <Card
              body
              style={{
                border: "0px",
                backgroundColor: "rgba(186, 191, 199, 0.12)",
                opacity: "Color/Light",
                marginTop: "10px",
              }}
            >
              <h5 style={{ color: "#5E5873", fontWeight: "bold" }}>
                {his.register_status}
              </h5>
              <p>
                ร้าน{his.franchise_name}สาขา {his.franchise_branchname}
                <br />
                สถานะ : {his.register_status}
                <br />
                สมัครไปเมื่อ : {moment(his.register_date).format("DD/MM/YYYY")}
                <br /> วันนัดสัมภาษณ์ :{" "}
                {his.interview_date === null
                  ? "ไม่มีวันนัดสัมภาษณ์"
                  : moment(his.interview_date).format("DD/MM/YYYY")}
                <br /> <a href="/detail">ดูรายละเอียดเพิ่มเติม</a>
              </p>
            </Card>
          </li>
        ))}
      </Card>
    </div>
  );
};

export default JobHistoryPage;
