import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import avatar from "../avatar.webp";
import map from "../maps.png";
import { ENDPOINT_URL } from "../../config/app-config";
import moment from "moment";
import Swal from "sweetalert2";

const franshise = [
  {
    name: "ห้วยขวาง",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
  {
    name: "ลาดพร้าววังหิน",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
  {
    name: "บางกะปิ",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
  {
    name: "รัชดา",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
  {
    name: "พระรามเก้า",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
  {
    name: "ประดิษมนูธรรม",
    position: "คนทำอาหาร",
    imageUrl: "mon",
  },
];

const test = "13.809004269013466, 100.57123405792991";

const JobfinderPage = ({ direction, ...args }) => {
  const [job, setJob] = useState({});
  const [job2, setJob2] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [positionID, setPosition] = useState("");
  const [filter, setFilter] = useState("");
  const [province, setPro] = useState("");
  const [apmer, setApm] = useState("");
  const [district, setDis] = useState("");
  const [positionOpen, setPositinOpen] = useState(false);
  const [allprovinces, setAll] = useState([]);
  const [allapmer, setAllapm] = useState([]);
  const [alldistrict, setAlldis] = useState([]);
  const [apmOpen, setApmOpen] = useState(false);
  const [disOpen, setDisOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [posi, setPosi] = useState([]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    getprovinces();
    getamperby_provinces(10);
    getdistrict(1001);
    getposition();
  }, []);

  const findjob = async () => {
    const jwt = localStorage.getItem("jwt_token");
    const annid = localStorage.getItem("ann_id");
    var id;
    if (annid === null) {
      id = "";
    } else {
      id = annid;
    }
    let body = {
      token: jwt,
      filter: {
        // announcement_id: id,
        position_1: filter,
        province: province,
        district: apmer,
        subdistrict: district,
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}findjob`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });

      var data = await response.json();
      if (data.code === 200) {
        if (data.message === "No data found") {
          Swal.fire({
            title: "ไม่มีข้อมูล",
            confirmButtonText: "ตกลง",
            timer: 2000,
          });
        } else setJob(data.result);
      } else {
        setJob([]);
      }
    } catch (err) {
      return err;
    }
  };

  const applyjob = async () => {
    console.log(job2);
    const jwt = localStorage.getItem("jwt_token");
    var position = "";
    {
      job2.position_id === 1
        ? (position = "พนักงานประจำสาขา")
        : (position = "พนักงานขายบริษัทฯ");
    }
    let body = {
      token: jwt,
      data: {
        announcement_id: job2.announcement_id,
        register_date: moment().format("YYYY-MM-DD"),
        position: position,
        franchise_id: job2.franchise_id,
        register_status: "ได้รับใบสมัคร",
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}applyjob`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });

      var data = await response.json();
      console.log(data);
      if (data.code === 200) {
        Swal.fire({
          title: "sucess",
          text: `ยื่นใบสมัครสำเร็จคุณได้ยื่นใบสมัครไปที่ ร้าน ${job2.franchise_name}`,
          icon: "success",
          confirmButtonText: "ตกลง",
          timer: 2000,
        });
      } else if (data.code === 500) {
        Swal.fire({
          title: "error",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          timer: 2000,
        });
      } else if (data.code === 422) {
        Swal.fire({
          title: "กรุณาเพิ่มข้อมูลที่อยู่ที่หน้าโปรไฟล์",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          timer: 2000,
        });
      }
    } catch (err) {
      return err;
    }
  };
  const getprovinces = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}allprovinces`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
      });
      var data = await response.json();
      setAll(data.result);
    } catch (err) {
      return err;
    }
  };

  const getamperby_provinces = async (value) => {
    let body = {
      province_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}districts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      setAllapm(data.result);
    } catch (err) {
      return err;
    }
  };

  const getdistrict = async (value) => {
    let body = {
      district_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}subdistricts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      setAlldis(data.result);
    } catch (err) {
      return err;
    }
  };

  const getposition = async () => {
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}allpositions`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
      });
      var data = await response.json();
      setPosi(data.result);
    } catch (err) {
      return err;
    }
  };

  const togglepo = () => setPositinOpen((prevState) => !prevState);
  const toggledrop = () => setDropdownOpen((prevState) => !prevState);
  const toggleapm = () => setApmOpen((prevState) => !prevState);
  const toggledis = () => setDisOpen((prevState) => !prevState);

  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Card
        body
        style={{
          border: "0px",
          marginTop: "20px",
          backgroundColor: "#49C5B1",
          // height: "300px",
          marginBottom: "15px",
        }}
        align="center"
      >
        {/* <CardImg
                                alt="Card image cap"
                                src={banner}
                                style={{
                                    height: '100%',
                                }}
                            /> */}

        <CardTitle
          className="d-md-block d-lg-block d-none"
          tag="h3"
          style={{ color: "#FFF" }}
        >
          ค้นหางาน
        </CardTitle>
        <CardTitle
          className="d-md-none d-lg-none"
          tag="h3"
          style={{ color: "#FFF", marginTop: "5px" }}
        >
          ค้นหางาน
        </CardTitle>
        <CardText
          className="d-md-block d-lg-block d-none"
          style={{ color: "#FFF", fontSize: "18px" }}
        >
          พิมประเภทงานเพื่อค้นหางานที่ชอบ
        </CardText>
        <CardText
          className="d-md-none d-lg-none"
          style={{ color: "#FFF", fontSize: "14px" }}
        >
          พิมประเภทงานเพื่อค้นหางานที่ชอบ
        </CardText>

        <Row>
          <Col lg="3" md="1" xs="1"></Col>
          <Col lg="7" md="10" xs="11">
            <Row style={{}}>
              <Input
                className="d-none d-md-block d-lg-none"
                id="exampleSearch"
                name="search"
                placeholder="search"
                type="search"
                style={{ width: "70%", marginRight: 20 }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <Input
                className="d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"
                id="exampleSearch"
                name="search"
                placeholder="search"
                type="search"
                style={{ width: "65%", marginRight: 10 }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <Input
                className="d-none d-lg-block"
                id="exampleSearch"
                name="search"
                placeholder="search"
                type="search"
                style={{ width: "70%", marginRight: 20 }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <Button
                className="d-none d-lg-block"
                style={{ width: "20%" }}
                color="success"
                onClick={() => {
                  localStorage.removeItem("ann_id");
                  findjob();
                }}
              >
                Search
              </Button>
              <Button
                className="d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none"
                style={{ width: "25%" }}
                color="success"
                onClick={() => {
                  localStorage.removeItem("ann_id");
                  findjob();
                }}
              >
                Search
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="2" className="d-none d-lg-block"></Col>
          <Col md="2" className="d-md-block d-lg-none d-none"></Col>
          <Col
            lg="2"
            md="12"
            xs="12"
            style={{ marginTop: "15px" }}
          >
            <Dropdown
              isOpen={positionOpen}
              toggle={togglepo}
              direction={direction}
              width="10%"
            >
              <DropdownToggle caret style={{ width: "80%" }}>
                {positionID === "" ? "ตำแหน่ง" : positionID}
              </DropdownToggle>
              <DropdownMenu
                style={{ width: "80%", height: "550%", overflow: "scroll" }}
              >
                <DropdownItem
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    setPosition("เลือกตำแหน่ง");
                    setFilter("");
                  }}
                >
                  เลือกตำแหน่ง
                </DropdownItem>
                {posi.map((item) => (
                  <DropdownItem
                  style={{fontSize: "14px"}}
                    onClick={(e) => {
                      setFilter(item.position_name);
                      setPosition(item.position_name);
                    }}
                  >
                    {item.position_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggledrop}
              direction={direction}
              width="10%"
            >
              <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                {province === "" ? "จังหวัด" : province}
              </DropdownToggle>
              <DropdownMenu
                {...args}
                style={{ width: "80%", height: "550%", overflow: "scroll" }}
              >
                {allprovinces.map((item) => (
                  <DropdownItem
                  style={{fontSize: "14px"}}
                    onClick={(e) => {
                      setPro(item.name_th);
                      getamperby_provinces(item.code);
                      setApm("");
                      setDis("");
                    }}
                  >
                    {item.name_th}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
            <Dropdown isOpen={apmOpen} toggle={toggleapm} direction={direction}>
              <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                {apmer === "" ? "อำเภอ" : apmer}
              </DropdownToggle>
              <DropdownMenu
                {...args}
                style={{ width: "80%", height: "550%", overflow: "scroll" }}
              >
                {allapmer.map((item) => (
                  <DropdownItem
                  style={{fontSize: "14px"}}
                    onClick={(e) => {
                      setApm(item.name_th);
                      getdistrict(item.code);
                      setDis("");
                    }}
                  >
                    {item.name_th}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
            <Dropdown isOpen={disOpen} toggle={toggledis} direction={direction}>
              <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                {district === "" ? "ตำบล" : district}
              </DropdownToggle>
              <DropdownMenu
                {...args}
                style={{ width: "80%", height: "550%", overflow: "scroll" }}
              >
                {alldistrict.map((item) => (
                  <DropdownItem
                  style={{fontSize: "14px"}}
                    onClick={(e) => {
                      setDis(item.name_th);
                    }}
                  >
                    {item.name_th}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </Card>

      <Row className="m-auto align-self-center">
        {/* <Col lg="3" className="d-xl-block d-none">
          {job.map((branch) => (
            <li key={branch.brand_ID} style={{ listStyleType: "none" }}>
              <Card body style={{ marginBottom: "10px" }}>
                <a
                  onClick={() => {
                    setJob2(branch);
                  }}
                >
                  <Row>
                    <Col lg="6" md="3" xs="6">
                      <img
                      src={require("../" + branch.imageUrl + ".jpg")}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    /> 
                    </Col>
                    <Col lg="6" md="5" xs="6">
                      <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                        {branch.franchise_name}
                      </CardTitle>
                      <CardText>
                        ตำแหน่ง : {branch.position_1}
                        <br />
                        สาขา : {branch.franchise_branchname}
                      </CardText>
                    </Col>
                  </Row>
                </a>
              </Card>
            </li>
          ))}
        </Col> */}
        {job.length == undefined ? (
          <div></div>
        ) : (
          job.map(
            (branch) => (
              console.log("test", branch),
              (
                <Col xl="6" lg="12">
                  <li key={branch.brand_ID} style={{ listStyleType: "none" }}>
                    <Card body style={{ marginBottom: "10px" }}>
                      <a
                        onClick={() => {
                          setJob2(branch);
                          toggle();
                        }}
                      >
                        <Row>
                          <Col md="3" xs="3">
                            {/* <img
                      src={require("../" + branch.imageUrl + ".jpg")}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    /> */}
                          </Col>
                          <Col lg="6" md="5" xs="6">
                            <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                              {branch.franchise_name}
                            </CardTitle>
                            <CardText>
                              ตำแหน่ง : {branch.position_1}
                              <br />
                              สาขา : {branch.franchise_branchname}
                            </CardText>
                          </Col>
                        </Row>
                      </a>
                    </Card>
                  </li>
                </Col>
              )
            )
          )
        )}

        <Modal fullscreen isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="2">
                <img
                  src={avatar}
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </Col>
              <Col>
                <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                  ร้าน {job2.franchise_name}
                </CardTitle>
                <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                  สาขา {job2.franchise_branchname}
                </CardTitle>
                <CardText>
                  ตำแหน่งที่ต้องการ : {job2.position_1}
                  <br />
                  {/* </CardText> */}
                  <Label>ที่อยู่ร้าน :</Label>
                  <a
                    href={`https://www.google.co.th/maps/place/SI-AM+Bag/@${test},14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06`}
                    target="_blank"
                    className="text-decoration"
                    style={{ marginLeft: "5px" }}
                  >
                    {job2.franchise_subdistrict} {job2.franchise_district}{" "}
                    {job2.franchise_province}{" "}
                  </a>
                </CardText>
              </Col>
              <CardTitle
                style={{
                  borderBottom: "2px solid",
                  marginTop: "15px",
                  marginBottom: "15px",
                  borderBottomColor: "#EBE9F1",
                }}
              />
            </Row>
            <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
              ร้าน {job2.franchise_name}
            </CardTitle>
            <CardText>{job2.job_description}</CardText>
            <Row>
              <Col sm="5">
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  รายละเอียดงาน
                </CardTitle>
                <CardText>
                  รูปแบบงาน : งานประจำ <br />
                  จำนวนที่รับ : {job2.position_required} ตำแหน่ง <br />
                  สถานที่ปฏิบัติงาน : กรุงเทพมหานคร(เขตห้วยขวาง)
                  <br />
                  เงินเดือน(บาท) : {job2.payroll}
                  <br />
                  เวลาทำงาน : สามารถเลือกเป็นกะ
                  <br />
                  เวลาทำงานอื่น : ไม่ระบุ
                  <br />
                </CardText>
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  หน้าที่ความรับผิดชอบ
                </CardTitle>
                <CardText>{`${job2.responsibilities}`}</CardText>
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  คุณสมบัติ
                </CardTitle>
                <CardText>{job2.about_job}</CardText>
              </Col>
              <Col sm="7">
                <img
                  src={map}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6%",
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                background: "#49C5B1",
                marginTop: "15px",
                border: "0px",
              }}
              type="submit"
              value="Submit"
              onClick={() => {
                applyjob();
              }}
            >
              ยื่นใบสมัคร
            </Button>
          </ModalFooter>
        </Modal>
        {/* <Col sm="9">
          <Card body className="d-none d-xl-block">
            <Row>
              <Col sm="2">
                <img
                  src={mon}
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </Col>
              <Col>
                <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                  ร้าน {job2.franchise_name}
                </CardTitle>
                <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                  สาขา {job2.franchise_branchname}
                </CardTitle>
                <CardText>
                  ตำแหน่งที่ต้องการ : {job2.position_1}
                  <br />
                  <Label>ที่อยู่ร้าน :</Label>
                  <a
                    href={`https://www.google.co.th/maps/place/SI-AM+Bag/@${test},14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06`}
                    target="_blank"
                    className="text-decoration"
                    style={{ marginLeft: "5px" }}
                  >
                    {job2.franchise_subdistrict} {job2.franchise_district}{" "}
                    {job2.franchise_province}{" "}
                  </a>
                </CardText>
              </Col>
              <CardTitle
                style={{
                  borderBottom: "2px solid",
                  marginTop: "15px",
                  marginBottom: "15px",
                  borderBottomColor: "#EBE9F1",
                }}
              />
            </Row>
            <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
              ร้าน {job2.franchise_name}
            </CardTitle>
            <CardText>{job2.job_description}</CardText>
            <Row>
              <Col sm="5">
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  คุณสมบัติงาน
                </CardTitle>
                <CardText>
                  รูปแบบงาน : งานประจำ <br />
                  จำนวนที่รับ : {job2.position_required} ตำแหน่ง <br />
                  สถานที่ปฏิบัติงาน : กรุงเทพมหานคร(เขตห้วยขวาง)
                  <br />
                  เงินเดือน(บาท) : {job2.payroll}
                  <br />
                  เวลาทำงาน : สามารถเลือกเป็นกะ
                  <br />
                  เวลาทำงานอื่น : ไม่ระบุ
                  <br />
                </CardText>
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  หน้าที่ความรับผิดชอบ
                </CardTitle>
                <CardText>{`${job2.responsibilities}`}</CardText>
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  คุณสมบัติ
                </CardTitle>
                <CardText>{job2.job_description}</CardText>
              </Col>
              <Col sm="7">
                <img
                  src={mon}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6%",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="10"></Col>
              <Col sm="2">
                <Button
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                  onClick={() => {
                    applyjob();
                  }}
                >
                  ยื่นใบสมัคร
                </Button>
              </Col>
            </Row>
          </Card>
        </Col> */}
      </Row>

      {/* {<Row className="m-auto align-self-center">
        <Col sm="3" style={{ marginTop: "15px" }}>
          {franshise.map((branch) => (
            <li key={branch.name} style={{ listStyleType: "none" }}>
              <Card body style={{ marginBottom: "10px" }}>
                <Row>
                  <Col sm="4">
                    <img
                      src={require("../" + branch.imageUrl + ".jpg")}
                      style={{
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  </Col>
                  <Col sm="8">
                    <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                      ไก่ย่างห้าดาว
                    </CardTitle>
                    <CardText>
                      สาขา : {branch.name} <br />
                      ตำแหน่ง : {branch.position}
                    </CardText>
                  </Col>
                </Row>
              </Card>
            </li>
          ))}
        </Col>
        <Col sm="9" style={{ marginTop: "15px" }}>
          <Form inline>
            <Card body>
              <Row>
                <Col sm="2">
                  <img
                    src={mon}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </Col>
                <Col>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    ร้าน ไก่ย่างห้าดาว
                  </CardTitle>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    สาขา ห้วยขวาง
                  </CardTitle>
                  <CardText>
                    ตำแหน่งที่ต้องการ : คนทำอาหาร
                    <br />
                    
                    <Label>ที่อยู่ร้าน : </Label>
                    <a
                      href="https://www.google.co.th/maps/place/SI-AM+Bag/@13.8038886,100.5489339,14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06"
                      target="_blank"
                      className="text-decoration"
                      style={{ marginLeft: "5px" }}
                    >
                      ห้วยขวาง ซอย 123
                    </a>
                  </CardText>
                </Col>
                <CardTitle
                  style={{
                    borderBottom: "2px solid",
                    marginTop: "15px",
                    marginBottom: "15px",
                    borderBottomColor: "#EBE9F1",
                  }}
                />
              </Row>
              <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                ร้าน ไก่ย่างห้าดาว
              </CardTitle>
              <CardText>
                หน้าที่การรับผิดชอบหลักของตำแหน่งนี้คือ
                การทำตามออเดอร์ที่ลูกค้าสั่งมาอย่างถูกต้อง
                มีความรู้ความเข้าใจเกี่ยวกับการทำอาหารอย่างถูกสุขลักษณะ และ
                มีความรับผิดชอบต่อหน้าที่ของตนเอง
                เพื่อตอบสนองความต้องการของลูกค้าในร้าน
              </CardText>
              <Row>
                <Col sm="5">
                  <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    คุณสมบัติงาน
                  </CardTitle>
                  <CardText>
                    รูปแบบงาน : งานประจำ <br />
                    จำนวนที่รับ : 3 ตำแหน่ง <br />
                    สถานที่ปฏิบัติงาน : กรุงเทพมหานคร(เขตห้วยขวาง)
                    <br />
                    เงินเดือน(บาท) : xxxxx - xxxxxx
                    <br />
                    เวลาทำงาน : สามารถเลือกเป็นกะ
                    <br />
                    เวลาทำงานอื่น : ไม่ระบุ
                    <br />
                  </CardText>
                  <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    หน้าที่ความรับผิดชอบ
                  </CardTitle>
                  <CardText>
                    -มีทางเทรนนิ่งสอนรายละเอียดตลอดการทำงาน
                    <br />
                    -แนะนำการขายกับลูกค้า
                    <br />
                    -มีความรับผิดชอบในการคิดเงิน
                    <br />
                  </CardText>
                  <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    คุณสมบัติ
                  </CardTitle>
                  <CardText>
                    เพศ : ชาย , หญิง
                    <br />
                    อายุ(ปี) : 25 - 36
                    <br />
                    ระดับการศึกษา : ปวส. - ปริญญาตรี
                    <br />
                    ประสบการณ์(ปี) : 1 - 3<br />
                    อื่นๆ : ยินดีรับนักศึกษาจบใหม่
                    <br />
                    ,ยินดีรับผู้ไม่มีประสบการณ์
                    <br />
                  </CardText>
                </Col>
                <Col sm="7" align="center" style={{ marginTop: "30px" }}>
                  <img
                    src={map}
                    style={{
                      width: "80%",
                      borderRadius: "6%",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="10"></Col>
                <Col sm="2">
                  <Button
                    style={{
                      background: "#49C5B1",
                      marginTop: "15px",
                      border: "0px",
                    }}
                    block
                    type="submit"
                    value="Submit"
                  >
                    ยื่นใบสมัคร
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>} */}
    </div>
  );
};

export default JobfinderPage;
