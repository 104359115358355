import "devextreme/dist/css/dx.light.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./component/page/Login/loginPage";
import RegisterPage from "./component/page/registerPage";
import VerificationPage from "./component/page/verificationPage";
import NavBar from "./component/navbar";
import ProfilePage from "./component/page/profilePage";
import HomePage from "./component/page/homePage";
import Profile_CPage from "./component/page/profile_CPage";
import JobFinderPage from "./component/page/jobFinderPage";
import JobFinder_CPage from "./component/page/jobFinder_CPage";
import JobHistoryPage from "./component/page/jobHistoryPage";
import WorkerFinderPage from "./component/page/workerFinderPage";
import WorkerHistoryPage from "./component/page/workerHistoryPage";
import Home_CPage from "./component/page/Home_c/home_CPage";
import Test from "./component/page/test";
import HomeTest from "./component/page/homeTest";
import WorkerFinder_T_MPage from "./component/page/workerFinder_T_MPage";
import JobFinder_T_MPage from "./component/page/jobFinder_T_MPage";
import FinderWorkerPage from "./component/page/finderworker";
import Finderwork_M_T from "./component/page/finderwork_M_T";
import NavBar_C from "./component/navbar_c";
import FinderWorker from "./component/page/finderworker";
import DetailPage from "./component/page/detialPage";
import Test2 from "./component/page/teat2";
import Resume from "./component/page/resume";
import Forget from "./component/page/forget";
import Verify from "./component/page/verify";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/verification">
          <VerificationPage />
        </Route>
        <Route path="/profile">
          <NavBar />
          <ProfilePage />
        </Route>
        <Route path="/home">
          <NavBar />
          <HomePage />
        </Route>
        <Route path="/home_C">
          <NavBar_C />
          <Home_CPage />
        </Route>
        <Route path="/profile_c">
          <NavBar_C />
          <Profile_CPage />
        </Route>
        <Route path="/jobFinder">
          <NavBar />
          <JobFinderPage />
        </Route>
        <Route path="/jobFinder_c">
          <NavBar_C />
          <JobFinder_CPage />
        </Route>
        <Route path="/jobHistory">
          <NavBar />
          <JobHistoryPage />
        </Route>
        <Route path="/workerFinder">
          <NavBar_C />
          <WorkerFinderPage />
        </Route>
        <Route path="/workerHistory">
          <NavBar_C />
          <WorkerHistoryPage />
        </Route>
        <Route path="/finderworker">
          <NavBar_C />
          <FinderWorker />
        </Route>
        <Route path="/test">
          <Test />
        </Route>
        <Route path="/homeT">
          <HomeTest />
        </Route>
        <Route exact path="/detail">
          <NavBar />
          <DetailPage />
        </Route>
        <Route path="/workerFinder_m">
          <NavBar_C />
          <WorkerFinder_T_MPage />
        </Route>
        <Route path="/jobFinder_m">
          <NavBar />
          <JobFinder_T_MPage />
        </Route>
        <Route path="/finderWorker">
          <NavBar_C />
          <FinderWorkerPage />
        </Route>
        <Route path="/finderWorker_m">
          <NavBar_C />
          <Finderwork_M_T />
        </Route>
        <Route path="/test2">
          <NavBar_C />
          <Test2 />
        </Route>
        <Route path="/resume">
          <Resume />
        </Route>
        <Route path="/forget">
          <Forget />
        </Route>
        <Route path="/verify">
          <Verify />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
