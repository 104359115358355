import React from "react";
import HomeC from "../page/Home_c/home_CPage";
import Test from "./test";

const homeTest = () => {
  return (
    <div>
      <HomeC />
      {/* <Test/> */}
    </div>
  );
};

export default homeTest;
