import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import avatar from "../avatar.webp";

const WorkerFinder_T_MPage = () => {
  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Card body>
        <CardTitle tag="h5">รายละเอียดผู้สมัคร</CardTitle>
        <CardTitle
          style={{ borderBottom: "2px solid", borderColor: "#EBE9F1" }}
        />
        <Row>
          <Col sm="2">
            <img
              src={avatar}
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "50%",
                marginBottom: "15px",
              }}
            />
          </Col>
          <Col sm="10">
            <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
              นางสาวงาม ล้นเหลือ
            </CardTitle>
            <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 1
            </CardTitle>
            <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 2
            </CardTitle>
            <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 3
            </CardTitle>
          </Col>
          <CardTitle
            style={{ borderBottom: "2px solid", borderColor: "#EBE9F1" }}
          ></CardTitle>
        </Row>
        <Row>
          <Col sm="1"></Col>
          <Col sm="5">
            <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
              ข้อมูลส่วนตัว
            </CardTitle>
            <CardText>
              อายุ : 22 <br />
              เพศ ชาย <br />
              ระดับการศึกษา : มัธยมต้น <br />
              เชื้อชาติ : ไทย <br />
              สัญชาติ : ไทย
            </CardText>
            <CardText>ที่อยู่ปัจจุบัน : 123 จอมพล เขต จตุจักร 10310</CardText>
            <CardText>
              ช่องทางการติดต่อ <br />
              เบอร์โทรศัพท์ : 09xxxxxxxx <br />
              E-mail : Phumkrit.sup@gmail.com
            </CardText>
            <p>หมายเหตุ</p>
            <Input
              id="exampleText"
              name="text"
              type="textarea"
              placeholder="หมายเหตุ"
            />
            <Row>
              <Col sm="6">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                >
                  รับเป็นพนักงาน
                </Button>
              </Col>
              <Col sm="6">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                >
                  ปฏิเสธการรับเข้า
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <CardText>
              ประสบการณ์การทำงาน 1 <br />
              สถานที่ /ตำแหน่ง <br />
              รายละเอียด
            </CardText>
            <CardText>
              ประสบการณ์การทำงาน 2 <br />
              สถานที่ /ตำแหน่ง <br />
              รายละเอียด
            </CardText>
            <CardText>
              ประวัติการอบรม
              <br />
              วันที่
              <br />
              หัวข้ออบรม
            </CardText>
            <Row>
              <Col sm="4">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                >
                  นัดสัมภาษณ์
                </Button>
              </Col>
              <Col sm="8">
                <Input
                  name="birdDate"
                  placeholder="DD/MM/YY"
                  type="date"
                  style={{ marginTop: "12px" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default WorkerFinder_T_MPage;
