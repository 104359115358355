import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Input, Select } from "antd";
import { useState, useEffect } from "react";
import avatar from "../avatar.webp";
import map from "../maps.png";
import { ENDPOINT_URL } from "../../config/app-config";
import Swal from "sweetalert2";
const { TextArea } = Input;
const JobFinder_CPage = () => {
  const [JWT, setJWT] = useState("");
  useEffect(() => {
    getJWT();
  }, []);

  const getJWT = async () => {
    const jwt = localStorage.getItem("jwt_token");
    const userdata = localStorage.getItem("userdata");
    setJWT(jwt);
    let data = JSON.parse(userdata);
    setFranID(data.worker_id);
  };

  const [franc, setFranID] = useState("");
  const [posreq, setPosReq] = useState("พนักงานประจำสาขา");
  const [aboutjob, setAboutjob] = useState("");
  const [jd, setResponsibi] = useState("");
  const [payroll, setpayroll] = useState("");
  const [responsibility, setResp] = useState("");
  const [amountreq, setAmountreq] = useState("");
  const [announce_stat, setAnnouncestat] = useState("");
  const [ct, setCt] = useState("");
  const [position, setPosi] = useState([]);
  const [posdetail, setPosdetail] = useState([]);
  const [jobname, setJobname] = useState("");
  const [note, setNote] = useState("Initial value");
  const [posi_id, setPosiId] = useState("1");
  const [positioname, setPositionName] = useState("พนักงานประจำสาขา");
  const [fran, setFran] = useState({});

  useEffect(() => {
    getposition();
    getOnepos();
    getworkerData();
  }, []);

  const getworkerData = async () => {
    const dataw = localStorage.getItem("userdata");
    let dataww = JSON.parse(dataw);
    try {
      var response = await fetch(`${ENDPOINT_URL}getonefran`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + dataww.token,
        },
        body: JSON.stringify({ franchise_id: dataww.worker_id }),
      });
      var data = await response.json();
      setFran(data.result[0]);
      console.log(data);
    } catch (err) {
      return err;
    }
  };

  const getposition = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}allpositions`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + JWT,
        },
      });
      var data = await response.json();
      setPosi(data.result);
      console.log(position);
    } catch (err) {
      return err;
    }
  };

  const AnnounePost = async (value) => {
    if (
      franc === "" ||
      payroll === "" ||
      responsibility === "" ||
      amountreq === ""
    ) {
      Swal.fire({
        title: "Error!",
        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    } else {
      var id;
      if (posi_id === "1") {
        id = 1;
      } else {
        id = posi_id;
      }
      let body = {
        token: JWT,
        data: {
          franchise_id: franc,
          /* postition_id1: "พนักงานประจำสาขา", */ //get จาก ตอนที่เลือก position,
          position_required: amountreq,
          about_job: aboutjob,
          responsibilities: responsibility,
          announcement_status: value,
          position_id: id,
          payroll: payroll,
          brand_ID: "5stars", //get จาก franchise table
          posting_date:
            new Date().toISOString().split("T")[0] +
            " " +
            new Date().toTimeString().split(" ")[0],
          position_1: jobname,
        },
      };
      try {
        var response = await fetch(`${ENDPOINT_URL}addannouncement`, {
          method: "POST",
          headers: {
            Accept: "appclication/json",
            "Content-Type": "appclication/json",
            Authorization: "Bearer " + JWT,
          },
          body: JSON.stringify(body),
        });
        var data = await response.json();
        console.log(data);

        if (data.code === 200) {
          Swal.fire({
            title: "Success!",
            text: "สร้างประกาศรับสมัครงานสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
        }
      } catch (err) {
        return err;
      }
    }
  };

  const getOnepos = async (value = "00001") => {
    let body = {
      position_id: value,
    };
    try {
      var response = await fetch(ENDPOINT_URL + "oneposition", {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + JWT,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      setAboutjob(data.result[0].position_description);
      setResp(data.result[0].responsibility);
      setpayroll(data.result[0].payroll);
      setAmountreq(data.result[0].position_getting);
    } catch (err) {
      return err;
    }
  };

  const mapIdtoname = () => {
    if (posi_id === 1) {
      setPositionName("พนักงานประจำสาขา");
    } else if (posi_id === 12) {
      setPositionName("พนักงานขายบริษัทฯ");
    }
  };
  const handleChange = (event) => {
    setAboutjob(event.target.value);
  };
  const handleChangeresp = (event) => {
    setResp(event.target.value);
  };
  const handleChangepayroll = (event) => {
    setpayroll(event.target.value);
  };
  const handleChangeAmountre = (event) => {
    setAmountreq(event.target.value);
  };

  const handleChangeNote = (event) => {
    setNote(event.target.value);
  };

  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Row className="m-auto align-self-center">
        {/* <Col sm="3">
          {franshise.map((branch) => (
            <li key={branch.name} style={{ listStyleType: "none" }}>
              <Card body style={{ marginBottom: "10px" }}>
                <Row>
                  <Col>
                    <img
                      src={require("../" + branch.imageUrl + ".jpg")}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </Col>
                  <Col>
                    <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                      ไก่ย่างห้าดาว
                    </CardTitle>
                    <CardText>
                      สาขา : {branch.name} <br />
                      ตำแหน่ง : {branch.position}
                    </CardText>
                  </Col>
                </Row>
              </Card>
            </li>
          ))}
        </Col> */}
        <Col sm="9" style={{ width: "1500px", paddingBottom: "10px" }}>
          <Card body>
            <Row>
              <Col lg="2" md="12" sm="3">
                <img
                  src={avatar}
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </Col>
              <Col>
                <CardTitle
                  tag="h4"
                  style={{ fontWeight: "bold", marginTop: "15px" }}
                >
                  ร้าน ไก่ย่างห้าดาว
                </CardTitle>
                <CardTitle style={{ fontWeight: "bold" }}>
                  สาขา {fran.franchise_branchname}
                </CardTitle>
                <Input
                  style={{ width: "50%" }}
                  id="franchise_id"
                  name="franchise_id"
                  type="text"
                  disabled
                  placeholder="TestDD"
                />
                <CardText style={{ fontWeight: "bold", marginTop: 5 }}>
                  ตำแหน่งที่ต้องการ <label style={{ color: "red" }}>*</label>
                  <br />
                  <Select
                    style={{ width: "50%" }}
                    onChange={(e, id) => {
                      console.log(e, id);
                      getOnepos(e);
                      setPosiId(e);
                      setJobname(id.children);
                      if (e === 1) {
                        setPositionName("พนักงานประจำสาขา");
                      } else if (e === 12) {
                        setPositionName("พนักงานขายบริษัทฯ");
                      }
                    }}
                    value={positioname}
                  >
                    {position.map((item) => (
                      <option key={item.position_id} value={item.position_id}>
                        {item.position_name}
                      </option>
                    ))}
                  </Select>
                  {/* <Input
                    style={{ width: "50%" }}
                    id="exampleSelect"
                    name="select"
                    type="select"
                    placeholder="ตำแหน่งที่ต้องการ"
                    onChange={(e) => {
                      setCt(e.target.value);
                      setPosdetail(e.target.value);
                      // if (e.target.value == "พนักงานประจำสาขา") {
                      //   fr_bio = positions[0].Bio;
                      //   fr_JD = positions[0].JD;
                      //   fr_Requires = positions[0].Requires;
                      //   fr_Descrition = positions[0].Description;

                      // } else if (e.target.value == 1) {
                      //   fr_bio = positions[1].Bio;
                      //   fr_JD = positions[1].JD;
                      //   fr_Requires = positions[1].Requires;
                      //   fr_Descrition = positions[1].Description;
                      // }
                      // console.log(fr_bio)
                    }}
                  >
                    {position.map((item) => (
                      <option key={item.position_id} value={item.position_id}>
                        {item.position_name}
                      </option>
                    ))}
                  </Input> */}
                </CardText>
              </Col>
              <CardTitle
                style={{
                  borderBottom: "2px solid",
                  marginTop: "15px",
                  marginBottom: "15px",
                  borderBottomColor: "#EBE9F1",
                }}
              />
            </Row>
            <Row>
              <Col sm="5">
                <label>คุณสมบัติ</label>
                <TextArea value={aboutjob} onChange={handleChange} />
                <label>หน้าที่ และ ความรับผิดชอบ</label>
                <TextArea value={responsibility} onChange={handleChangeresp} />
                <label>เงินเดือน (เป็นตัวเลขเท่านั้น)</label>
                <Input
                  value={payroll}
                  onChange={handleChangepayroll}
                  type="number"
                />
                <label>
                  จำนวนที่รับ <label style={{ color: "red" }}>*</label>
                </label>
                <Input value={amountreq} onChange={handleChangeAmountre} />
                {/* <label>หมายเหตุ</label>
                <TextArea value={note} onChange={handleChangeNote} /> */}
              </Col>
              <Col sm="2"></Col>
              <Col sm="5" style={{ marginRight: "50", marginTop: "20px" }}>
                <img
                  src={map}
                  style={{
                    width: "70%",
                    borderRadius: "6%",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="10" md="9" sm="8"></Col>
              <Col lg="2" md="3" sm="4">
                <Button
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                  onClick={() => {
                    setAnnouncestat("public");
                    AnnounePost("public");
                  }}
                >
                  ลงประกาศ
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JobFinder_CPage;
