import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import avatar from "../avatar.webp";
import { useState } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import { useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";

/* const worker = [
  {
    name: "นางสาวงาม ล้นเหลือ",
    job: "แคชเชียร์",
  },
  {
    name: "นายสุขใจ ใจดี",
    job: "พนักงานเสิร์ฟ",
  },
  {
    name: "นายกินดี มีสุข",
    job: "พนักงานเสิร์ฟ",
  },
  {
    name: "นายสมบัติ รักงาน",
    job: "พ่อครัว",
  },
  {
    name: "นางดีงาม ขยันจัง",
    job: "พนักงานเสิร์ฟ",
  },
  {
    name: "นางสาวใจสุข มั่งมี",
    job: "พ่อครัว",
  },
]; */

const WorkerFinderPage = (args) => {
  const [worker, setWorker] = useState({});
  const [work, setWorker2] = useState([]);
  const [confirm, setConfirm] = useState();
  const [inter, setInter] = useState("");
  const [filter, setFilter] = useState("");
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [remark, setremark] = useState("");
  const [sort, setSort] = useState([]);
  useEffect(() => {
    findworker();
    sortitem();
  }, []);
  useEffect(() => {
    apply();
  }, [confirm]);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const interview = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      interview_date: inter,
      register_id: work.register_id,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}interview`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });

      var data = await response.json();
      if (data.code === 200) {
        console.log("test");
        Swal.fire({
          title: "success",
          text: `ท่านได้นัด ${work.fname} เข้าสัมภาษณ์แล้ว ในวันที่ ${moment(
            inter
          ).format("DD/MM/YYYY")}`,
          icon: "success",
          confirmButtonText: "ยืนยัน",
        }).then((result) => {
          if (result.isConfirmed) {
            findworker();
            setModal2(false);
          }
        });
      }
    } catch (err) {
      return;
    }
  };

  const apply = async (value) => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      is_confirm: confirm,
      worker_id: work.worker_id,
      register_id: work.register_id,
      remark: remark,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}acceptdeny`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      console.log(data);
      if (data.code === 200) {
        if (confirm === true) {
          Swal.fire({
            title: "success",
            text: `ท่านได้รับ ${work.fname} เข้าทำงานแล้ว`,
            icon: "success",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.isConfirmed) {
              setModal2(false);
              findworker();
            }
          });
        }
        if (confirm === false) {
          Swal.fire({
            title: "success",
            text: `ท่านได้ปฏิเสธ ${work.fname} เข้าทำงานแล้ว`,
            icon: "success",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.isConfirmed) {
              setModal2(false);
              findworker();
            }
          });
        }
      }
    } catch (err) {
      return err;
    }
  };

  const findworker = async () => {
    const jwt = localStorage.getItem("jwt_token");
    const id = localStorage.getItem("worker_id");
    var wid;
    if (id === "null") {
      wid = "";
    } else {
      wid = id;
    }
    let body = {
      token: jwt,
      filter: {
        fname: filter,

        // worker_id: wid,
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}workerregisterrecord`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });

      var data = await response.json();
      if (data.code === 200) {
        if (data.message === "No data found") {
          Swal.fire({
            title: "ไม่มีข้อมูล",
            confirmButtonText: "ตกลง",
            timer: 2000,
          });
        } else setWorker(data.result);
      } else {
        setWorker([]);
      }
    } catch (err) {
      return;
    }
  };

  const checkDate = () => {
    if (work.interview_date === null) {
      return "";
    } else {
      return moment(work.interview_date).format("DD/MM/YYYY");
    }
  };

  const checkDateregister = () => {
    if (work.register_date === "") {
      return "";
    } else {
      return moment(work.register_date).format("DD/MM/YYYY");
    }
  };

  const sortitem = () => {
    var sort = work.sort((a, b) => {
      return a.register_date > b.register_date;
    });

    setSort(sort);
  };

  const checkDateseminar = () => {
    if (work.seminar_course_date_1 === "") {
      return "";
    } else {
      return moment(work.seminar_course_date_1).format("DD/MM/YYYY");
    }
  };
  const checkDateseminar2 = () => {
    if (work.seminar_course_date_2 === "") {
      return "";
    } else {
      return moment(work.seminar_course_date_2).format("DD/MM/YYYY");
    }
  };
  return (
    console.log(sort),
    (
      <div className="container" style={{ marginTop: "90px" }}>
        <Card
          body
          align="center"
          inverse
          style={{
            /*  border: "2px solid #EBE9F1", */
            marginTop: "20px",
            height: 150,
            marginBottom: "15px",
            /* borderColor: "#EBE9F1", */
          }}
        >
          <CardTitle
            className="d-md-block d-lg-block d-none"
            tag="h3"
            style={{ color: "#000", marginTop: "5px" }}
          >
            ค้นหาผู้สมัคร
          </CardTitle>
          <CardTitle
            className="d-md-none d-lg-none sm-block"
            tag="h3"
            style={{ marginTop: "5px", color: "#000" }}
          >
            ค้นหาผู้สมัคร
          </CardTitle>
          <Row>
            <Col lg="3" md="1" xs="1"></Col>
            <Col lg="7" md="10" xs="11">
              <Row>
                <Input
                  className="d-none d-md-block d-lg-none"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "70%", marginRight: 20 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Input
                  className="d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "65%", marginRight: 10 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Input
                  className="d-none d-lg-block"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "70%", marginRight: 20 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Button
                  className="d-none d-lg-block"
                  style={{ width: "20%" }}
                  color="success"
                  onClick={() => {
                    findworker();
                  }}
                >
                  Search
                </Button>
                <Button
                  className="d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none"
                  style={{ width: "25%" }}
                  color="success"
                  onClick={() => {
                    findworker();
                  }}
                >
                  Search
                </Button>
              </Row>
            </Col>
          </Row>
        </Card>
        <Row className="m-auto align-self-center">
          {worker.length == undefined ? (
            <div></div>
          ) : (
            worker
              .sort((a, b) => a.register_date - b.register_date)
              .map((works) => (
                <Col xl="4" sm="12" /* className="d-xl-none d-block" */>
                  <li
                    key={works.worker_id}
                    style={{ listStyleType: "none", marginBottom: "10px" }}
                  >
                    <Card body>
                      <a
                        onClick={() => {
                          setWorker2(works);
                          toggle2();
                        }}
                      >
                        <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                          {works.fname} {works.lname}
                        </CardTitle>
                        <CardText tag="h6">{works.register_status}</CardText>
                        <CardText tag="h6">
                          {works.register_date != ""
                            ? moment(works.register_date).format("DD/MM/YYYY")
                            : moment().format("DD/MM/YYYY")}
                        </CardText>
                      </a>
                    </Card>
                  </li>
                </Col>
              ))
          )}

          <Modal fullscreen isOpen={modal2} toggle={toggle2} {...args}>
            <ModalHeader toggle={toggle2}>รายละเอียดผู้สมัคร</ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="2">
                  <img
                    src={avatar}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                      marginBottom: 15,
                    }}
                  />
                </Col>
                <Col sm="10">
                  <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
                    {work.fname} {work.lname}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    ตำแหน่งงานที่ยื่นสมัคร : {work.position}
                  </CardTitle>
                </Col>
                <CardTitle
                  style={{
                    borderBottom: "2px solid",
                    borderColor: "#EBE9F1",
                    marginBottom: "10px",
                  }}
                ></CardTitle>
              </Row>
              <Row>
                <Col sm="1"></Col>
                <Col sm="5">
                  <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                    ข้อมูลส่วนตัว
                  </CardTitle>
                  <CardText>
                    อายุ : {work.age} <br />
                    เพศ : {work.gender} <br />
                    ระดับการศึกษา : {work.highest_Education} <br />
                    เชื้อชาติ : {work.nationality} <br />
                    สัญชาติ : {work.country}
                  </CardText>
                  <CardText>ที่อยู่ปัจจุบัน : {work.current_address}</CardText>
                  <CardText>
                    ช่องทางการติดต่อ <br />
                    เบอร์โทรศัพท์ : {work.tel} <br />
                    E-mail : {work.email}
                  </CardText>
                </Col>
                <Col sm="6">
                  <CardText>
                    ประสบการณ์การทำงาน 1 <br />
                    ตำแหน่ง : {work.experience_1}
                    <br />
                    สถานที่: {work.experience_1_place}
                    <br />
                    รายละเอียด : {work.experience_1_detail}
                  </CardText>
                  <CardText>
                    ประสบการณ์การทำงาน 2 <br />
                    ตำแหน่ง : {work.experience_2}
                    <br />
                    สถานที่ : {work.experience_2_place}
                    <br />
                    รายละเอียด : {work.experience_2_detail}
                  </CardText>
                  <CardText>
                    ประวัติการอบรม 1
                    <br />
                    วันที่ :{" "}
                    {work.seminar_course_date_1 === ""
                      ? ""
                      : moment(work.seminar_course_date_1).format("DD/MM/YYYY")}
                    <br />
                    หัวข้ออบรม :{" "}
                    {`${
                      work.seminar_course_1 === undefined
                        ? ""
                        : work.seminar_course_1
                    }`}
                  </CardText>
                  <CardText>
                    ประวัติการอบรม 2
                    <br />
                    วันที่ : {checkDateseminar2()}
                    <br />
                    หัวข้ออบรม :{" "}
                    {`${
                      work.seminar_course_2 === undefined
                        ? ""
                        : work.seminar_course_2
                    }`}
                  </CardText>
                  <CardText>
                    สถานะการรับสมัคร : {work.register_status}{" "}
                  </CardText>
                  <CardText>วันยี่นใบสมัคร : {checkDateregister()}</CardText>
                  {console.log(work)}
                  <CardText>
                    วันนัดสัมภาษณ์ :{" "}
                    {work.register_status == "อยู่ระหว่างการสัมภาษณ์" ||
                    "อยู่ระหว่างการพิจารณา"
                      ? checkDate()
                      : checkDate()
                      ? ""
                      : ""}
                  </CardText>
                  <Row>
                    <Col lg="8" md="7"></Col>
                    {/*    <Col lg="4" md="5">
                      <Button
                        color="secondary"
                        onClick={toggle}
                        style={{ fontSize: "15px" }}
                        outline
                        block
                      >
                        ประกาศนียบัตร
                      </Button>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                        <ModalBody>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={toggle}>
                            Do Something
                          </Button>{" "}
                          <Button color="secondary" onClick={toggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col> */}
                    <Col lg="8" md="7"></Col>
                    {/* <Col lg="4" md="5">
                      <Button
                        color="secondary"
                        onClick={toggle3}
                        style={{ fontSize: "15px", marginTop: 20 }}
                        outline
                        block
                      >
                        resume
                      </Button>
                      <Modal isOpen={modal3} toggle={toggle3}>
                        <ModalHeader toggle={toggle3}>Modal title</ModalHeader>
                        <ModalBody>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={toggle3}>
                            Do Something
                          </Button>{" "}
                          <Button color="secondary" onClick={toggle3}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col> */}
                    <Row style={{ marginTop: "20px" }}>
                      {work.register_status === "ได้รับใบสมัคร" ? (
                        <Row style={{ marginTop: "20px" }}>
                          <Col md="6" lg="8">
                            <Label>นัดวันสัมภาษณ์</Label>
                            <Input
                              name="birdDate"
                              placeholder="DD/MM/YY"
                              type="date"
                              onChange={(e) => {
                                /*  Command.interview_date;/*  */
                                setInter(e.target.value);
                              }}
                            />
                          </Col>
                          <Col md="6" lg="4">
                            <button
                              style={{
                                background: "#7367F0",
                                marginTop: "30px",
                                //border: "0px",
                                borderRadius: "5px",
                                color: "#fff",
                                borderColor: "gray",
                                width: "100%",
                                height: "35px",
                              }}
                              block
                              type="submit"
                              value="Submit"
                              onClick={() => {
                                interview();
                              }}
                            >
                              นัดสัมภาษณ์
                            </button>
                          </Col>
                        </Row>
                      ) : work.register_status == "อยู่ระหว่างการสัมภาษณ์" ||
                        "อยู่ระหว่างการพิจารณา" ? (
                        ""
                      ) : work.register_status == "ปฏิเสธการสมัคร" ||
                        "รับเข้าทำงาน" ? (
                        ""
                      ) : (
                        ""
                      )}
                    </Row>
                  </Row>
                </Col>
                <Col sm="1"></Col>
                <Col sm="5">
                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      sm="12"
                      style={{ marginTop: 80 }}
                      className="d-lg-none d-md-none d-block"
                    >
                      <p>หมายเหตุ</p>
                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        placeholder="หมายเหตุ"
                        onChange={(e) => {
                          setremark(e.target.value);
                        }}
                      />
                    </Col>
                    <Col sm="12" className="d-xl-block d-md-block d-none">
                      <Label>หมายเหตุ</Label>
                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        placeholder="หมายเหตุ"
                        onChange={(e) => {
                          setremark(e.target.value);
                        }}
                      />
                    </Col>
                    {work.register_status === "ปฏิเสธการสมัคร" ? (
                      ""
                    ) : work.register_status === "รับเข้าทำงาน" ? (
                      ""
                    ) : work.register_status === "ได้รับใบสมัคร" ? (
                      ""
                    ) : (
                      <div>
                        <Row>
                          <Col md="6">
                            <Button
                              style={{
                                background: "#7367F0",
                                marginTop: "15px",
                                border: "0px",
                              }}
                              block
                              type="submit"
                              value="Submit"
                              onClick={() => {
                                setConfirm(true);
                                var con = true;
                                //  apply(con);
                              }}
                            >
                              รับเป็นพนักงาน
                            </Button>
                          </Col>
                          <Col md="6">
                            <Button
                              style={{
                                background: "#7367F0",
                                marginTop: "15px",
                                border: "0px",
                              }}
                              block
                              type="submit"
                              value="Submit"
                              onClick={() => {
                                setConfirm(false);
                                var con = false;
                                // apply(con);
                              }}
                            >
                              ปฏิเสธการรับเข้า
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle2}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {/* <Col sm="3" className="d-xl-block d-none">
            {worker.map((work) => (
              <li
                key={work.worker_id}
                style={{ listStyleType: "none", marginBottom: "10px" }}
              >
                <Card body>
                  <a
                    onClick={() => {
                      setWorker2(work);
                    }}
                  >
                    <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                      {work.job}
                    </CardTitle>
                    <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                      {work.fname} {work.lname}
                    </CardTitle>
                  </a>
                </Card>
              </li>
            ))}
          </Col>
          <Col sm="9">
            <Card body className="d-none d-xl-block">
              <CardTitle tag="h5">รายละเอียดผู้สมัคร</CardTitle>
              <CardTitle
                style={{
                  borderBottom: "2px solid",
                  borderColor: "#EBE9F1",
                }}
              />
              <Row>
                <Col sm="2">
                  <img
                    src={mon}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                      marginBottom: 15,
                    }}
                  />
                </Col>
                <Col sm="10">
                  <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
                    {work.fname} {work.lname}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    ตำแหน่งงานที่ยื่นสมัคร : {work.position_1}
                  </CardTitle>
                </Col>
                <CardTitle
                  style={{
                    borderBottom: "2px solid",
                    borderColor: "#EBE9F1",
                    marginBottom: "10px",
                  }}
                ></CardTitle>
              </Row>
              <Row>
                <Col sm="1"></Col>
                <Col sm="5">
                  <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                    ข้อมูลส่วนตัว
                  </CardTitle>
                  <CardText>
                    อายุ : {work.age} <br />
                    เพศ : {work.gender} <br />
                    ระดับการศึกษา : {work.highest_Education} <br />
                    เชื้อชาติ : {work.nationality} <br />
                    สัญชาติ : {work.country}
                  </CardText>
                  <CardText>ที่อยู่ปัจจุบัน : {work.current_address}</CardText>
                  <CardText>
                    ช่องทางการติดต่อ <br />
                    เบอร์โทรศัพท์ : {work.tel} <br />
                    E-mail : {work.email}
                  </CardText>
                  <CardTitle>
                    <Label style={{ fontWeight: "bold" }}>
                      ตำแหน่งที่เลือก <br />
                    </Label>
                    <br />
                    ตำแหน่งที่ 1 : {work.position_1}
                    <br />
                    ตำแหน่งที่ 2 : {work.position_2}
                    <br />
                    ตำแหน่งที่ 3 : {work.position_3}
                  </CardTitle>
                </Col>
                <Col sm="6">
                  <CardText>
                    ประสบการณ์การทำงาน 1 <br />
                    สถานที่ /ตำแหน่ง : {work.experience_1_place}/
                    {work.experience_1} <br />
                    รายละเอียด : {work.experience_1_detail}
                  </CardText>
                  <CardText>
                    ประสบการณ์การทำงาน 2 <br />
                    สถานที่ /ตำแหน่ง : {work.experience_2_place}/
                    {work.experience_2}
                    <br />
                    รายละเอียด : {work.experience_2_detail}
                  </CardText>
                  <CardText>
                    ประวัติการอบรม
                    <br />
                    วันที่ : {work.seminar_course_date_1}
                    <br />
                    หัวข้ออบรม : {work.seminar_course_1}
                  </CardText>
                  <CardText>สถานะการรับสมัคร : </CardText>
                  <CardText>วันยี่นใบสมัคร : </CardText>
                  <Row>
                    <Col lg="8" md="7"></Col>
                    <Col lg="4" md="5">
                      <Button
                        color="secondary"
                        onClick={toggle}
                        style={{ fontSize: "15px" }}
                        outline
                        block
                      >
                        ประกาศนียบัตร
                      </Button>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                        <ModalBody>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={toggle}>
                            Do Something
                          </Button>{" "}
                          <Button color="secondary" onClick={toggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md="6" lg="8">
                        <Label>นัดวันสัมภาษณ์</Label>
                        <Input
                          name="birdDate"
                          placeholder="DD/MM/YY"
                          type="date"
                          onChange={(e) => setInter(e.target.value)}
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <Button
                          style={{
                            background: "#7367F0",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          block
                          type="submit"
                          value="Submit"
                          onClick={() => {
                            interview();
                          }}
                        >
                          นัดสัมภาษณ์
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col sm="1"></Col>
                <Col sm="5">
                  <Row style={{ marginTop: "-70px" }}>
                    <Col sm="12" className="d-lg-none d-md-none d-block">
                      <p>หมายเหตุ</p>
                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        placeholder="หมายเหตุ"
                      />
                    </Col>
                    <Col sm="12" className="d-xl-block d-md-block d-none">
                      <Label>หมายเหตุ</Label>
                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        placeholder="หมายเหตุ"
                      />
                    </Col>
                    <Col lg="6">
                      <Button
                        style={{
                          background: "#7367F0",
                          marginTop: "15px",
                          border: "0px",
                        }}
                        disabled={inter != "" ? false : true}
                        block
                        type="submit"
                        value="Submit"
                        onClick={() => {
                          apply(true);
                        }}
                      >
                        รับเป็นพนักงาน
                      </Button>
                    </Col>
                    <Col lg="6">
                      <Button
                        style={{
                          background: "#7367F0",
                          marginTop: "15px",
                          border: "0px",
                        }}
                        disabled={inter != "" ? false : true}
                        block
                        type="submit"
                        value="Submit"
                        onClick={() => {
                          apply(false);
                        }}
                      >
                        ปฏิเสธการรับเข้า
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card> 
          </Col>*/}
        </Row>
      </div>
    )
  );
};

export default WorkerFinderPage;
