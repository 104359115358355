import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import avatar from "../avatar.webp";
import { ENDPOINT_URL } from "../../config/app-config";

const Finderwork_M_T = ({ direction, ...args }) => {
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [test, setTest] = useState([]);
  const [allprovinces, setAll] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [apmOpen, setApmOpen] = useState(false);
  const [disOpen, setDisOpen] = useState(false);
  const [test2, setTest2] = useState({
    worker_id: 0,
    fname: "",
    lname: "",
    tel: "",
    tel2: "",
    experience_1: "",
    experience_2: "",
    experience_3: "",
    nationality: "",
    country: "",
    birthdate: "",
    gender: "",
    current_address: "",
    hometown_address: "",
    position_1: "",
    position_2: "",
    position_3: "",
    address_subdistrict: "",
    address_district: "",
    address_province: "",
    highest_Education: "",
    school: "",
    status_id: "",
    announment_id: "",
    franchise_id: "",
    picture: "",
    resume: "",
    seminar_course_1: "",
    seminar_course_date_1: "",
    seminar_proof_1: "",
    job_started_date: "",
    publish_status: "",
    last_publish_date: "",
    user_id: "",
    age: "",
    email: "",
    seminar_course_2: "",
    seminar_course_date_2: "",
    seminar_proof_2: "",
    seminar_course_3: "",
    seminar_course_date_3: "",
    seminar_proof_3: "",
    address_zipcode: "",
    experience_1_detail: "",
    experience_2_detail: "",
    experience_3_detail: "",
    experience_1_place: "",
    experience_2_place: "",
    experience_3_place: "",
  });
  const [allapmer, setAllapm] = useState([]);
  const [alldistrict, setAlldis] = useState([]);
  const [province, setPro] = useState("");
  const [apmer, setApm] = useState("");
  const [district, setDis] = useState("");
  const [interview, setInter] = useState("");
  const [confirm, setCon] = useState("");
  const [apmm, setApmm] = useState("");

  useEffect(() => {
    getprovinces();
    getamperby_provinces(10);
    getdistrict(1001);
  }, []);

  const postinterview = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      interview_data: interview,
      register_id: "",
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}interview`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
    } catch (err) {
      return err;
    }
  };

  const apply = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      is_confirm: confirm,
      register_id: "",
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}acceptdeny`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
    } catch (err) {
      return err;
    }
  };

  const getprovinces = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}allprovinces`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
      });
      var data = await response.json();
      let proV = data?.result;
      proV.unshift({
        id: 0,
        code: "0",
        name_th: "จังหวัด",
        name_en: "Provinces",
        geography_id: 0,
      });
      setAll(proV);
    } catch (err) {
      return err;
    }
  };

  const getamperby_provinces = async (value) => {
    let body = {
      province_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}districts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      let allAper = data?.result;
      allAper.unshift({
        id: 0,
        code: "0",
        name_th: "อำเภอ",
        name_en: "Amphures",
        geography_id: 0,
      });
      setAllapm(allAper);
    } catch (err) {
      return err;
    }
  };
  const getdistrict = async (value) => {
    let body = {
      district_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}subdistricts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      let district = data?.result;
      district.unshift({
        id: 0,
        zip_code: "0",
        name_th: "ตำบล",
        name_en: "District",
        district_code: 0,
      });
      setAlldis(district);
    } catch (err) {
      return err;
    }
  };

  const findworker = async () => {
    const jwt = localStorage.getItem("jwt_token");
    var apm;
    if (apmm === 0) {
      apm = "";
    } else {
      apm = apmer;
    }
    let body = {
      token: jwt,
      filter: {
        position_1: filter,
        address_province: province,
        address_district: apm,
        address_subdistrict: district,
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}findworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      setTest(data.result);
    } catch (err) {
      return err;
    }
  };

  const toggle = () => setModal(!modal);
  const toggledrop = () => setDropdownOpen((prevState) => !prevState);
  const toggleapm = () => setApmOpen((prevState) => !prevState);
  const toggledis = () => setDisOpen((prevState) => !prevState);

  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Card body>
        <CardTitle tag="h5">รายละเอียดผู้สมัคร</CardTitle>
        <CardTitle
          style={{ borderBottom: "2px solid", borderColor: "#EBE9F1" }}
        />
        <Row>
          <Col md="1" className="d-md-block d-none"></Col>
          <Col lg="1" sm="12" md="2">
            <img
              src={avatar}
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "50%",
                marginBottom: "15px",
              }}
            />
          </Col>
          <Col sm="8">
            <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
              นางสาวงาม ล้นเหลือ
            </CardTitle>
            <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 1
            </CardTitle>
            <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 2
            </CardTitle>
            <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
              ตำแหน่งที่ 3
            </CardTitle>
          </Col>
          <CardTitle
            style={{ borderBottom: "2px solid", borderColor: "#EBE9F1" }}
          ></CardTitle>
        </Row>
        <Row>
          <Col sm="1"></Col>
          <Col sm="5">
            <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
              ข้อมูลส่วนตัว
            </CardTitle>
            <CardText>
              อายุ : 22 <br />
              เพศ ชาย <br />
              ระดับการศึกษา : มัธยมต้น <br />
              เชื้อชาติ : ไทย <br />
              สัญชาติ : ไทย
            </CardText>
            <CardText>ที่อยู่ปัจจุบัน : 123 จอมพล เขต จตุจักร 10310</CardText>
            <CardText>
              ช่องทางการติดต่อ <br />
              เบอร์โทรศัพท์ : 09xxxxxxxx <br />
              E-mail : Phumkrit.sup@gmail.com
            </CardText>
          </Col>
          <Col sm="6">
            <CardText>
              ประสบการณ์การทำงาน 1 <br />
              สถานที่ /ตำแหน่ง <br />
              รายละเอียด
            </CardText>
            <CardText>
              ประสบการณ์การทำงาน 2 <br />
              สถานที่ /ตำแหน่ง <br />
              รายละเอียด
            </CardText>
            <CardText>
              ประวัติการอบรม
              <br />
              วันที่
              <br />
              หัวข้ออบรม
            </CardText>
            <Row>
              <Col lg="9" md="7"></Col>
              <Col lg="3" md="5">
                <Button
                  color="secondary"
                  onClick={toggle}
                  style={{ fontSize: "15px" }}
                  outline
                  block
                >
                  ประกาศนียบัตร
                </Button>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                      Do Something
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
              <Col md="6" lg="8">
                <Label>นัดวันสัมภาษณ์</Label>
                <Input
                  name="birdDate"
                  placeholder="DD/MM/YY"
                  type="date"
                  onClick={(e) => {
                    setInter(e.target.value);
                  }}
                />
              </Col>
              <Col md="6" lg="4">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "30px",
                    border: "0px",
                  }}
                  block
                  type="submit"
                  value="Submit"
                  onClick={() => {
                    postinterview();
                  }}
                >
                  นัดสัมภาษณ์
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="1"></Col>
          <Col sm="5">
            <Row>
              <Col
                sm="12"
                style={{ marginTop: "30px" }}
                className="d-lg-none d-md-none d-block"
              >
                <p>หมายเหตุ</p>
                <Input
                  id="exampleText"
                  name="text"
                  type="textarea"
                  placeholder="หมายเหตุ"
                />
              </Col>
              <Col
                sm="12"
                style={{ marginTop: "-70px" }}
                className="d-lg-block d-md-block d-none"
              >
                <Label>หมายเหตุ</Label>
                <Input
                  id="exampleText"
                  name="text"
                  type="textarea"
                  placeholder="หมายเหตุ"
                />
              </Col>
              <Col lg="6">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  disabled
                  block
                  type="submit"
                  value="Submit"
                  onClick={() => {
                    apply(true);
                  }}
                >
                  รับเป็นพนักงาน
                </Button>
              </Col>
              <Col lg="6">
                <Button
                  style={{
                    background: "#7367F0",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  disabled
                  block
                  type="submit"
                  value="Submit"
                  onClick={() => {
                    apply(false);
                  }}
                >
                  ปฏิเสธการรับเข้า
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default Finderwork_M_T;
