import React from "react";
import OtpInput from "react-otp-input";
import {
  Card,
  CardTitle,
  CardText,
  Button,
  Form,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useState } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import { useEffect } from "react";
import Swal from "sweetalert2";

const VerificationPage = () => {
  const [code, setCode] = useState("");
  const [users, setUser] = useState("");
  const [tokens, setToken] = useState("");
  const [passwordreset, setPassword] = useState("");
  const [phone_reset, setPhone] = useState("");

  useEffect((e) => {
    const user = localStorage.getItem("username");
    console.log("phone_reset", user);
    // if (user != "") {
    //   otpres();
    // }
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("token");
    if (Token) {
      setToken(Token);
    }
    const user = localStorage.getItem("phone_reset");
    if (user) {
      setUser(user);
    }
    const body = localStorage.getItem("regisinfo");
    const password = localStorage.getItem("phone_reset");
    if (passwordreset) {
      setPassword(password);
    }
    const username = localStorage.getItem("phone_reset");
    if (phone_reset) {
      setPhone(username);
    }
  });

  const changePwd = async () => {
    const jwt = localStorage.getItem("jwt_token");
    const username = localStorage.getItem("phone_reset");
    const password = localStorage.getItem("password_reset");
    let body = {
      phone: username,
      new_password: password,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}changpwd`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
    } catch (err) {
      return err;
    }
  };

  const handleChange = (code) => setCode(code);

  const verfityOtp = async () => {
    const Token = localStorage.getItem("token");
    let body = {
      token: Token,
      pin: code,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otpverify`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      if (result.status === "success") {
        Swal.fire({
          title: "Success",
          text: "Success",
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then((e) => {
          if (e.isConfirmed) {
            window.location.href = "/";
          }
        });
        changePwd();
        // registerPost();
      } else if (result.code === 400) {
        Swal.fire({
          title: "OTP ผิด",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      }
      console.log(response);
    } catch (err) {
      return err;
    }
  };

  const otpres = async () => {
    const user = localStorage.getItem("phone_reset");
    let body = {
      phone: user,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otprequest`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      console.log(result.token);
      localStorage.setItem("token", result.token);
    } catch (err) {
      return err;
    }
  };

  const registerPost = async () => {
    const body = localStorage.getItem("regisinfo");
    try {
      var response = await fetch(`${ENDPOINT_URL}regiswork`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        window.location("/");
      }
    } catch (err) {
      return err;
    }
  };
  return (
    <div className="container">
      <Form inline>
        <Container className="d-flex vh-100">
          <Row className="m-auto align-self-center">
            <Col>
              <Card
                body
                className="my-2"
                style={{
                  width: "407px",
                  height: "414px",
                }}
              >
                <CardTitle align="center" tag="h2">
                  Next Compile Website
                </CardTitle>
                <CardText tag="h4" style={{ marginBottom: "15px" }}>
                  ยืนยันตัวตน
                </CardText>
                <Label>เราส่งรหัสยืนยันตัวตนไปยังเบอร์มือถือของท่านแล้ว</Label>
                <Label style={{ marginTop: "-10px" }}>
                  ห้ามแจ้งรหัสกับบุคคลที่สามทุกกรณี
                </Label>
                <CardText tag="h4" style={{ marginTop: "10px" }}>
                  {users}
                </CardText>

                <Form
                  justify="center"
                  align="center"
                  style={{ marginTop: "15px" }}
                >
                  <OtpInput
                    align="center"
                    value={code}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span style={{ width: "8px" }}></span>}
                    inputStyle={{
                      border: "1px solid",
                      borderRadius: "7px",
                      width: "55px",
                      height: "55px",
                      borderColor: "#D8D6DE",
                    }}
                  />
                </Form>

                <Button
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  //tag="input"
                  onClick={() => {
                    verfityOtp();
                  }}
                >
                  ยืนยันบัญชี
                </Button>
                <FormGroup align="center" style={{ marginTop: "25px" }}>
                  <Label>หากไม่ได้รับรหัส</Label>
                  {/* // NOTE - WARNING */}
                  <a
                    href="#"
                    className="text-decoration-none"
                    style={{ marginLeft: "5px", color: "#49C5B1" }}
                    onClick={() => {
                      otpres();
                    }}
                  >
                    ส่งใหม่
                  </a>
                </FormGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default VerificationPage;
