import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import banner from "../Workwith_banner.jpg";
import monster from "../monster_joystick.png";
import logo from "../logo.jpg";
import { ENDPOINT_URL } from "../../config/app-config";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import avatar from "../avatar.webp";
import moment from "moment";
import map from "../maps.png";
import { isEmptyArray } from "formik";

const test = "13.809004269013466, 100.57123405792991";

const HomePage = ({ direction, ...args }) => {
  const [ann, setAnn] = useState([1]);
  const [userData, setData] = useState({});
  const [job, setJob] = useState([]);
  const [job2, setJob2] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const data = localStorage.getItem("userdata");
    setData(JSON.parse(data));
    if (userData.login_firsttime === true) {
      console.log("rest");
      window.location = "/profile";
    } else {
      homeann();
    }

    // getworkerData();
  }, []);

  const getworkerData = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}getaworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + userData.token,
        },
        body: JSON.stringify({ worker_id: userData.worker_id }),
      });
      var data = await response.json();
      console.log(data);
    } catch (err) {
      return err;
    }
  };

  const applyjob = async () => {
    const jwt = localStorage.getItem("jwt_token");
    var position = "";
    {
      job2.position_id === 1
        ? (position = "พนักงานประจำสาขา")
        : (position = "พนักงานขายบริษัทฯ");
    }
    let body = {
      token: jwt,
      data: {
        announcement_id: job2.announcement_id,
        register_date: moment().format("YYYY-MM-DD HH:MM:SS"),
        position: position,
        franchise_id: job2.franchise_id,
        register_status: "ได้รับใบสมัคร",
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}applyjob`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });

      var data = await response.json();
      console.log(data);
      if (data.code === 200) {
        Swal.fire({
          title: "sucess",
          text: `ยื่นใบสมัครสำเร็จคุณได้ยื่นใบสมัครไปที่ ร้าน ${job2.franchise_name}`,
          icon: "success",
          confirmButtonText: "ตกลง",
          timer: 2000,
        });
      } else if (data.code === 500) {
        Swal.fire({
          title: "error",
          text: data.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          timer: 2000,
        });
      }
    } catch (err) {
      return err;
    }
  };

  const homeann = async () => {
    const dataw = localStorage.getItem("userdata");
    let dataww = JSON.parse(dataw);
    const userdata = localStorage.getItem("datuser");
    let user = JSON.parse(userdata);
    console.log(user);
    setData(JSON.parse(dataw));
    try {
      var response = await fetch(`${ENDPOINT_URL}announcementforworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + dataww.token,
        },
        body: JSON.stringify({
          token: dataww.token,
          filter: {
            province: user.address_province,
            district: user.address_district,
            subdistrict: user.address_subdistrict,
          },
        }),
      });
      var data = await response.json();
      if (data.code === 404) {
      } else if (data.code === 200) {
        if (data.result.length !== 0) setAnn(data.result);
      }
    } catch (err) {
      return err;
    }
  };
  return (
    console.log(ann.length),
    (
      <div
        className="container"
        style={{ marginTop: "90px", marginBottom: "30px" }}
      >
        <Form inline>
          <Container>
            <Row className="m-auto align-self-center">
              <Card
                inverse
                style={{
                  border: "0px",
                  marginTop: "20px",
                  marginBottom: "15px",
                }}
              >
                <CardImg
                  alt="Card image cap"
                  src={banner}
                  style={{}}
                  width="100%"
                />
              </Card>
              <Row className="m-auto align-self-center" align="center">
                {console.log(ann.length)}
                {ann.length == undefined ? (
                  <h1>
                    {console.log("ann", userData)}
                    ไม่มีประกาศหาพนักงานใกล้คุณ กรุณาค้นหาเพื่อหางานใน
                    ละแวกอื่นๆ
                  </h1>
                ) : (
                  ann.map((announ) => (
                    <Col md={6} lg={4} style={{ marginTop: "15px" }}>
                      <Card
                        style={{
                          width: "100%",
                        }}
                      >
                        <a
                          style={{ color: "black", textDecoration: "none" }}
                          // href="/jobFinder"
                          onClick={() => {
                            setJob2(announ);
                            toggle();
                          }}
                        >
                          <img
                            alt="Sample"
                            src={logo}
                            style={{
                              borderRadius: "6px 6px 0px 0px",
                              height: "225px",
                            }}
                          />
                          <CardBody>
                            <CardTitle tag="h5">
                              ตำแหน่ง :{" "}
                              {announ.position_id === 1
                                ? "Cashier"
                                : announ.position_id === 2
                                ? "พนักงานประจำสาขา"
                                : announ.position_id === 3
                                ? "พนักงานขายบริษัทฯ"
                                : announ.position_id === 4
                                ? "Chef Head"
                                : announ.position_id === 5
                                ? "Salesman"
                                : ""}
                            </CardTitle>
                            <CardText>
                              {" "}
                              {announ.franchise_name}{" "}
                              {announ.franchise_branchname}{" "}
                            </CardText>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>
                  ))
                )}

                <Modal fullscreen isOpen={modal} toggle={toggle} {...args}>
                  <ModalHeader toggle={toggle}></ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col sm="2">
                        <img
                          src={avatar}
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                          }}
                        />
                      </Col>
                      <Col>
                        <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                          ร้าน {job2.franchise_name}
                        </CardTitle>
                        <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                          สาขา {job2.franchise_branchname}
                        </CardTitle>
                        <CardText>
                          ตำแหน่งที่ต้องการ :{" "}
                          {job2.position_id === 1
                            ? "Cashier"
                            : job2.position_id === 2
                            ? "พนักงานประจำสาขา"
                            : job2.position_id === 3
                            ? "พนักงานขายบริษัทฯ"
                            : job2.position_id === 4
                            ? "Chef Head"
                            : job2.position_id === 5
                            ? "Salesman"
                            : ""}
                          <br />
                          {/* </CardText> */}
                          <Label>ที่อยู่ร้าน :</Label>
                          <a
                            href={`https://www.google.co.th/maps/place/SI-AM+Bag/@${test},14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06`}
                            target="_blank"
                            className="text-decoration"
                            style={{ marginLeft: "5px" }}
                          >
                            {job2.franchise_subdistrict}{" "}
                            {job2.franchise_district} {job2.franchise_province}{" "}
                          </a>
                        </CardText>
                      </Col>
                      <CardTitle
                        style={{
                          borderBottom: "2px solid",
                          marginTop: "15px",
                          marginBottom: "15px",
                          borderBottomColor: "#EBE9F1",
                        }}
                      />
                    </Row>
                    <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                      ร้าน {job2.franchise_name}
                    </CardTitle>
                    <CardText>{job2.job_description}</CardText>
                    <Row>
                      <Col sm="5">
                        <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                          รายละเอียดงาน
                        </CardTitle>
                        <CardText>
                          รูปแบบงาน : งานประจำ <br />
                          จำนวนที่รับ : {job2.position_required} ตำแหน่ง <br />
                          สถานที่ปฏิบัติงาน : กรุงเทพมหานคร(เขตห้วยขวาง)
                          <br />
                          เงินเดือน(บาท) : {job2.payroll}
                          <br />
                          เวลาทำงาน : สามารถเลือกเป็นกะ
                          <br />
                          เวลาทำงานอื่น : ไม่ระบุ
                          <br />
                        </CardText>
                        <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                          หน้าที่ความรับผิดชอบ
                        </CardTitle>
                        <CardText>{`${job2.responsibilities}`}</CardText>
                        <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                          คุณสมบัติ
                        </CardTitle>
                        <CardText>{job2.about_job}</CardText>
                      </Col>
                      <Col sm="7">
                        <img
                          src={map}
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "6%",
                          }}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      style={{
                        background: "#49C5B1",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        applyjob();
                      }}
                    >
                      ยื่นใบสมัคร
                    </Button>
                  </ModalFooter>
                </Modal>
              </Row>
            </Row>
          </Container>
        </Form>
      </div>
    )
  );
};

export default HomePage;
