import React from 'react'
import { Col, Row, Container } from 'reactstrap'

const Resume = () => {
    return (
        <div>
            <div>
                <div
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        width: '210mm',
                        height: '297mm',

                    }}
                >
                    <Container>
                        <Row className="m-auto align-self-center">
                            <Col xs='6' style={{ marginTop: '50px' }}>
                                <div align='center'>
                                    <img
                                        src={''}
                                        style={{
                                            height: "250px",
                                            width: "250px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '3px' }}>ตำแหน่งงานที่สนใจสมัคร</div>
                                <div style={{ marginTop: '15px', marginLeft: '20px' }}>
                                    <div>1. </div>
                                    <div>2. </div>
                                    <div>3. </div>
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '3px' }}>ชื่อ-สกุล</div>
                                <div style={{ marginTop: '15px', marginLeft: '20px' }}>

                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '3px' }}>ระดับการศึกษาสูงสุด</div>
                                <div style={{ marginTop: '15px', marginLeft: '20px' }}>

                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '3px' }}>ประวัติการเข้าอบรม</div>
                                <div style={{ marginTop: '15px', marginLeft: '20px' }}>
                                    <div></div>
                                    <div></div>
                                </div>
                            </Col>
                            <Col xs='6' style={{ marginTop: '50px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '15px' }}>ช่องทางติดต่อ</div>
                                <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '15px' }}>ที่อยู่</div>
                                <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                                    <div></div>
                                    <div></div>
                                    <div> รหัสไปรษณีย์ </div>
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '15px' }}>ข้อมูลส่วนบุคคล</div>
                                <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                                    <div>อายุ  ปี เพศ </div>
                                    <div>เชื้อชาติ  สัญชาติ </div>
                                </div>
                                <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '15px', marginLeft: '15px' }}>ประสบการทำงาน</div>
                                <div style={{ marginTop: '15px', marginLeft: '30px' }}>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div >
    )
}

export default Resume