import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ListGroupItem,
  CardHeader,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { dataSource, employees } from "./dataSource";
/* import { Chart, Series } from "devextreme-react/chart"; */
import { ENDPOINT_URL } from "../../../config/app-config.js";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection,
  Button,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import "@progress/kendo-theme-default/dist/all.css";
import avatar from "../../avatar.webp";
import moment from "moment";
import Swal from "sweetalert2";

const Home_CPage = (args) => {
  const [JWT, setJWT] = useState("");
  const [chart, setChart] = useState([]);
  const [chart2, setChart2] = useState([]);
  const [chart3, setChart3] = useState([]);
  const [chart4, setChart4] = useState([]);
  const [chart5, setChart5] = useState([]);
  const [table, setTable] = useState([]);
  const [work, setWorker2] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [inter, setInter] = useState("");
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [test, setTest] = useState({});
  const [item, setItem] = useState({});
  const [regis_id, setRegisid] = useState("");
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const [remark, setremark] = useState("");
  const [userData, setData] = useState({});

  const [Command, setCommand] = useState({
    name: "",
    position: "",
    telephone: "",
    interview_date: "",
    register_status: "",
    apply_date: "",
  });

  useEffect(() => {
    /*  getJWT(); */
    const data = localStorage.getItem("userdata");
    setData(JSON.parse(data));
    console.log(userData);
    status();
    workerdetail();
    /* setItem("worker_id", null); */
    if (userData.login_firsttime === true) {
      console.log("rest");
      window.location = "/forget";
    } else {
    }
  }, []);
  const interview = async (e) => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      interview_date: inter,
      register_id: e,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}interview`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        Swal.fire({
          title: "success",
          text: `ท่านได้นัด ${test.fname} เข้าสัมภาษณ์แล้ว ในวันที่ ${moment(
            inter
          ).format("DD/MM/YYYY")}`,
          icon: "success",
          confirmButtonText: "ยืนยัน",
        });
      }
    } catch (err) {
      return;
    }
  };
  const apply = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      is_confirm: true,
      worker_id: Command.worker_id,
      register_id: Command.register_id,
      remark: remark,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}acceptdeny`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        Swal.fire({
          title: "success",
          text: `ท่านได้รับ ${test.fname} เข้าทำงานแล้ว`,
          icon: "success",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            workerdetail();
            setModal2(!modal2);
          }
        });
      }
      // if (data.code === 200) {
      //   console.log("hello");
      //   setConfirm("");
      // }
    } catch (err) {
      return err;
    }
  };
  const applyfalse = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      is_confirm: false,
      worker_id: Command.worker_id,
      register_id: Command.register_id,
      remark: remark,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}acceptdeny`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        Swal.fire({
          title: "success",
          text: `ท่านได้ปฏิเสธ ${test.fname} เข้าทำงานแล้ว`,
          icon: "success",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            workerdetail();
            setModal2(!modal2);
          }
        });
      }
      // if (data.code === 200) {
      //   console.log("hello");
      //   setConfirm("");
      // }
    } catch (err) {
      return err;
    }
  };

  const getJWT = async () => {
    const jwt = localStorage.getItem("jwt_token");
    setJWT(jwt);
  };
  const status = async () => {
    getJWT();
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}franstatuscount`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ token: jwt }),
      });
      var data = await response.json();
      setChart([data.result.ได้รับใบสมัคร]);
      setChart2([data.result.อยู่ระหว่างการสัมภาษณ์]);
      setChart3([data.result.อยู่ระหว่างการพิจารณา]);
      setChart4([data.result.รับเข้าทำงาน]);
      setChart5([data.result.ปฏิเสธการสมัคร]);
    } catch (err) {
      return err;
    }
  };

  const workerdetail = async () => {
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}franworkerdetail`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ token: jwt }),
      });
      var data = await response.json();
      setTable(data.result);
    } catch (err) {
      return err;
    }
  };

  const findworker = async (value) => {
    const jwt = localStorage.getItem("jwt_token");
    let id = value.toString();
    const arr = value.split(" ");

    let name = arr[0];
    let body = {
      token: jwt,
      filter: { fname: name },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}workerregisterrecord`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      setTest(data.result[0]);
    } catch (err) {
      return err;
    }
  };
  const checkDate = () => {
    if (Command.interview_date === null) {
      return "";
    } else {
      return moment(Command.interview_date).format("DD/MM/YYYY");
    }
  };

  const checkDateseminar = () => {
    console.log(test.seminar_course_date_1);
    if (test.seminar_course_date_1 === "") {
      return "";
    } else {
      return moment(test.seminar_course_date_1).format("DD/MM/YYYY");
    }
  };
  const checkDateseminar2 = (e) => {
    if (e === "") {
      return "";
    } else {
      return moment(e).format("DD/MM/YYYY");
    }
  };

  return (
    <div className="container" style={{ paddingTop: "80px" }}>
      <Row>
        <Col sm="3">
          <Card body>
            <CardText>แสดงสถานะการสมัคร(คน)</CardText>
            <Row>
              <Col sm="2">
                <Label
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#05E4FF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Col>
              <Col sm="10">
                <p>ได้รับใบสมัคร : {chart === "" ? 0 : chart}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <Label
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#467FFF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Col>
              <Col sm="10">
                <p>อยู่ระหว่างการสัมภาษณ์ : {chart2 === "" ? 0 : chart2}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <Label
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#E2CC57",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Col>
              <Col sm="10">
                <p>อยู่ระหว่างการพิจารณา : {chart3 === "" ? 0 : chart3}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <Label
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#28C76F",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Col>
              <Col sm="10">
                <p>รับเข้าทำงาน : {chart4 === "" ? 0 : chart4}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <Label
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#FF6D6D",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                />
              </Col>
              <Col sm="10">
                <p>ปฏิเสธการสมัคร : {chart5 === "" ? 0 : chart5}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm="9">
          <Card body style={{ height: 275 }}>
            <Chart style={{ height: 275 }}>
              <ChartSeries>
                <ChartSeriesItem data={chart} color="#05E4FF" />
                <ChartSeriesItem data={chart2} color="#467FFF" />
                <ChartSeriesItem data={chart3} color="#E2CC57" />
                <ChartSeriesItem data={chart4} color="#28C76F" />
                <ChartSeriesItem data={chart5} color="#FF6D6D" />
              </ChartSeries>
            </Chart>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: "30px", marginBottom: "30px" }}>
        <CardHeader body style={{ background: "#FFF", overflow: "scroll" }}>
          <Row>
            <Col sm="9" style={{ padding: "5px" }}>
              ข้อมูลผู้สมัคร
            </Col>
            <Col sm="3">
              <ListGroupItem></ListGroupItem>
            </Col>
          </Row>
        </CardHeader>
        <div>
          <DataGrid
            id="gridContainer"
            dataSource={table}
            keyExpr="worker_id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders={true}
            style={{ overflow: "scroll" }}
          >
            <Selection mode="single" />
            <ColumnFixing enabled={true} />
            <Column caption="Employee" width={230} dataField="name" />
            <Column dataField="telephone" />
            <Column dataField="apply_date" dataType="date" />
            <Column dataField="position" />
            <Column dataField="register_status" />
            <Column dataField="Detail" fixed={true} type="buttons">
              <Button
                text="More info"
                hint="More info"
                onClick={(e) => {
                  /* localStorage. */ setItem("worker_id", e.row.key);
                  setWorker2(e.row.key);
                  findworker(e.row.data.name);
                  toggle2();
                  setCommand(e.row.data);
                  setRegisid(e.row.key);
                  console.log(e.row.data);
                  //window.location = "/workerFinder";
                }}
              />
            </Column>
            <Column dataField="remark" />
          </DataGrid>
        </div>
      </Card>
      <Modal fullscreen isOpen={modal2} toggle={toggle2} {...args}>
        <ModalHeader toggle={toggle2}>รายละเอียดผู้สมัคร</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="2">
              <img
                src={avatar}
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                  marginBottom: 15,
                }}
              />
            </Col>
            <Col sm="10">
              <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
                {Command.name}
              </CardTitle>
              <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                ตำแหน่งงานที่ยื่นสมัคร : {Command.position}
              </CardTitle>
            </Col>
            <CardTitle
              style={{
                borderBottom: "2px solid",
                borderColor: "#EBE9F1",
                marginBottom: "10px",
              }}
            ></CardTitle>
          </Row>
          <Row>
            <Col sm="1"></Col>
            <Col sm="5">
              <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                ข้อมูลส่วนตัว
              </CardTitle>
              <CardText>
                อายุ : {test.age} <br />
                เพศ : {test.gender} <br />
                ระดับการศึกษา : {test.highest_Education} <br />
                เชื้อชาติ : {test.nationality} <br />
                สัญชาติ : {test.country}
              </CardText>
              <CardText>ที่อยู่ปัจจุบัน : {test.current_address}</CardText>
              <CardText>
                ช่องทางการติดต่อ <br />
                เบอร์โทรศัพท์ : {test.tel} <br />
                E-mail : {test.email}
              </CardText>
              {/* <CardTitle style={{ marginBottom: "40px" }}>
                  <Label style={{ fontWeight: "bold" }}>
                    ตำแหน่งที่เลือก <br />
                  </Label>
                  <br />
                  ตำแหน่งที่ 1 : {test.position_1}
                  <br />
                  ตำแหน่งที่ 2 : {test.position_2}
                  <br />
                  ตำแหน่งที่ 3 : {test.position_3}
                </CardTitle> */}
            </Col>
            <Col sm="6">
              <CardText>
                ประสบการณ์การทำงาน 1 <br />
                ตำแหน่ง : {test.experience_1}
                <br />
                สถานที่ : {test.experience_1_place} <br />
                รายละเอียด : {test.experience_1_detail}
              </CardText>
              <CardText>
                ประสบการณ์การทำงาน 2 <br />
                ตำแหน่ง : {test.experience_2}
                <br />
                สถานที่ : {test.experience_2_place}
                <br />
                รายละเอียด : {test.experience_2_detail}
              </CardText>
              <CardText>
                ประวัติการอบรม 1
                {/* <br />
                วันที่ : {checkDateseminar()} */}
                <br />
                หัวข้ออบรม : {test.seminar_course_1}
              </CardText>
              <CardText>
                ประวัติการอบรม 2
                {/* <br />
                วันที่ : {checkDateseminar2(test.seminar_course_date_2)} */}
                <br />
                หัวข้ออบรม : {test.seminar_course_2}
              </CardText>
              <CardText>สถานะการรับสมัคร : {test.register_status} </CardText>
              <CardText>
                วันนัดสัมภาษณ์ :{" "}
                {Command.register_status == "อยู่ระหว่างการสัมภาษณ์" ||
                "อยู่ระหว่างการพิจารณา"
                  ? checkDate()
                  : checkDate()
                  ? ""
                  : ""}
              </CardText>
              <CardText>
                วันยี่นใบสมัคร :{" "}
                {moment(Command.apply_date).format("DD/MM/YYYY")}
              </CardText>
              <Row style={{ marginTop: "20px" }}>
                {Command.register_status === "ได้รับใบสมัคร" ? (
                  <Row style={{ marginTop: "20px" }}>
                    <Col md="6" lg="8">
                      <Label>นัดวันสัมภาษณ์</Label>
                      <Input
                        name="birdDate"
                        placeholder="DD/MM/YY"
                        type="date"
                        onChange={(e) => {
                          /*  Command.interview_date;/*  */
                          setInter(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md="6" lg="4">
                      <button
                        style={{
                          background: "#7367F0",
                          marginTop: "30px",
                          //border: "0px",
                          borderRadius: "5px",
                          color: "#fff",
                          borderColor: "gray",
                          width: "100%",
                          height: "35px",
                        }}
                        block
                        type="submit"
                        value="Submit"
                        onClick={() => {
                          interview(Command.register_id);
                        }}
                      >
                        นัดสัมภาษณ์
                      </button>
                    </Col>
                  </Row>
                ) : Command.register_status == "อยู่ระหว่างการสัมภาษณ์" ||
                  "อยู่ระหว่างการพิจารณา" ? (
                  ""
                ) : Command.register_status == "ปฏิเสธการสมัคร" ||
                  "รับเข้าทำงาน" ? (
                  ""
                ) : (
                  ""
                )}
              </Row>
            </Col>
            <Col sm="1"></Col>

            <Col sm="5">
              {Command.register_status == "ปฏิเสธการสมัคร" ? (
                <Row style={{ marginTop: "-70px" }}>
                  <Col
                    sm="12"
                    style={{ marginTop: 80 }}
                    className="d-lg-none d-md-none d-block"
                  >
                    <p>หมายเหตุ</p>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      disabled
                      value={Command.remark}
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        setremark(e.target.value);
                      }}
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 40 }}
                    sm="12"
                    className="d-xl-block d-md-block d-none"
                  >
                    <Label>หมายเหตุ</Label>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      disabled
                      value={Command.remark}
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        setremark(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              ) : Command.register_status == "รับเข้าทำงาน" ? (
                ""
              ) : Command.register_status == "ได้รับใบสมัคร" ? (
                ""
              ) : (
                <Row style={{ marginTop: "-70px" }}>
                  <Col
                    sm="12"
                    style={{ marginTop: 80 }}
                    className="d-lg-none d-md-none d-block"
                  >
                    <p>หมายเหตุ</p>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      value={remark}
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        setremark(e.target.value);
                      }}
                    />
                  </Col>
                  <Col
                    style={{ marginTop: 40 }}
                    sm="12"
                    className="d-xl-block d-md-block d-none"
                  >
                    <Label>หมายเหตุ</Label>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      value={remark}
                      placeholder="หมายเหตุ"
                      onChange={(e) => {
                        setremark(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Row style={{ paddingLeft: "80px" }}>
              <Col lg="6"></Col>
              {Command.register_status === "ได้รับใบสมัคร" ? (
                ""
              ) : Command.register_status === "รับเข้าทำงาน" ? (
                ""
              ) : Command.register_status === "ปฏิเสธการสมัคร" ? (
                ""
              ) : (
                <Row>
                  <Col lg="3">
                    <button
                      style={{
                        background: "#7367F0",
                        marginTop: "15px",
                        border: "0px",
                        borderRadius: "5px",
                        color: "#fff",
                        height: "35px",
                        paddingLeft: "30%",
                        paddingRight: "30%",
                      }}
                      block
                      disabled={
                        Command.register_status === "ได้รับใบสมัคร"
                          ? true
                          : Command.register_status === "รับเข้าทำงาน"
                          ? true
                          : Command.register_status === "ปฏิเสธการสมัคร"
                          ? true
                          : false
                      }
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        apply();
                      }}
                    >
                      รับเป็นพนักงาน
                    </button>
                  </Col>
                  <Col lg="3">
                    <button
                      style={{
                        background: "#7367F0",
                        marginTop: "15px",
                        border: "0px",
                        borderRadius: "5px",
                        color: "#fff",
                        height: "35px",
                        paddingLeft: "30%",
                        paddingRight: "30%",
                      }}
                      block
                      disabled={
                        Command.register_status === "ได้รับใบสมัคร"
                          ? true
                          : Command.register_status === "รับเข้าทำงาน"
                          ? true
                          : Command.register_status === "ปฏิเสธการสมัคร"
                          ? true
                          : false
                      }
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        setConfirm(false);
                        var con = false;
                        applyfalse();
                        console.log(confirm);
                      }}
                    >
                      ปฏิเสธการรับเข้า
                    </button>
                  </Col>
                </Row>
              )}
            </Row>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            style={{
              background: "#394eed",
              borderRadius: "5px",
              color: "#fff",
              height: "35px",
            }}
            color="primary"
            onClick={toggle2}
          >
            OK
          </button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Home_CPage;

const data = [
  {
    id: "1",
    name: "นายสุขใจ ใจดี",
    phone: "08xxxxxxxx",
    date: "28/04/2022",
    position: "แคชเชียร์",
    status: "อยู่ระหว่างการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "2",
    name: "นางสาวงาม ล้นเหลือ",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "อยู่ไกลจากร้านเกินไป",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "3",
    name: "นายหา ไม่เจอ",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "ไม่สะดวกกะกลางคืน",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "4",
    name: "นายรอ ไปเถอะ",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "5",
    name: "นางสาวอยู่ ที่ไหน",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "6",
    name: "นายรอ ไม่นาน",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "7",
    name: "นางสาวสดใส จังเลย",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "8",
    name: "นางสาวมาลี อร่อยนะ",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "9",
    name: "นางสาวหยุด ได้แล้ว",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "10",
    name: "นายอย่า เพิ่งไป",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "11",
    name: "นางสาวไป เถอะเธอ",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "",
    moreinfo: true,
    imageUrl: "mon",
  },
  {
    id: "12",
    name: "นายหาย ไปแล้ว",
    phone: "091xxxxxxx",
    date: "28/04/2022",
    position: "พนักงานเสิร์ฟ",
    status: "ไม่ผ่านการสัมภาษณ์",
    note: "-",
    moreinfo: true,
    imageUrl: "mon",
  },
];

const chart = [
  { year: "1950", population: 2.525 },
  { year: "1960", population: 3.018 },
  { year: "1970", population: 3.682 },
  { year: "1980", population: 4.44 },
  { year: "1990", population: 5.31 },
  { year: "2000", population: 6.127 },
  { year: "2010", population: 6.93 },
];
