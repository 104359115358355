import React, { useEffect } from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardText,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import avatar from "../avatar.webp";
import { useState } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import moment from "moment";

const FinderWorkerPage = ({ direction, ...args }) => {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [positionID, setPosition] = useState("");
  const [filter, setFilter] = useState("");
  const [test, setTest] = useState([]);
  const [allprovinces, setAll] = useState([]);
  const [positionOpen, setPositinOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [apmOpen, setApmOpen] = useState(false);
  const [disOpen, setDisOpen] = useState(false);
  const [posi, setPosi] = useState([]);
  const [test2, setTest2] = useState({
    worker_id: 0,
    fname: "",
    lname: "",
    tel: "",
    tel2: "",
    experience_1: "",
    experience_2: "",
    experience_3: "",
    nationality: "",
    country: "",
    birthdate: "",
    gender: "",
    current_address: "",
    hometown_address: "",
    position_1: "",
    position_2: "",
    position_3: "",
    address_subdistrict: "",
    address_district: "",
    address_province: "",
    highest_Education: "",
    school: "",
    status_id: "",
    announment_id: "",
    franchise_id: "",
    picture: "",
    resume: "",
    seminar_course_1: "",
    seminar_course_date_1: "",
    seminar_proof_1: "",
    job_started_date: "",
    publish_status: "",
    last_publish_date: "",
    user_id: "",
    age: "",
    email: "",
    seminar_course_2: "",
    seminar_course_date_2: "",
    seminar_proof_2: "",
    seminar_course_3: "",
    seminar_course_date_3: "",
    seminar_proof_3: "",
    address_zipcode: "",
    experience_1_detail: "",
    experience_2_detail: "",
    experience_3_detail: "",
    experience_1_place: "",
    experience_2_place: "",
    experience_3_place: "",
  });
  const [allapmer, setAllapm] = useState([]);
  const [alldistrict, setAlldis] = useState([]);
  const [address_province, setPro] = useState("");
  const [apmer, setApm] = useState("");
  const [address_district, setDis] = useState("");
  const [interview, setInter] = useState("");
  const [confirm, setCon] = useState("");
  const [apmm, setApmm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter2, setFilter2] = useState("");

  useEffect(() => {
    getprovinces();
    getamperby_provinces(10);
    getdistrict(1001);
    getposition();
  }, []);
  useEffect(() => {
    apply();
  }, [confirm]);

  const postinterview = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      data: {
        interview_date: interview,
        user_id: test2.user_id,
        register_date: moment().format("YYYY-MM-DD"),
        position: "ยื่นข้อเสนอ",
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}pickworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        Swal.fire({
          icon: "success",
          text: `ท่านได้นัดสัมภาษณ์ ${test2.fname} ในวันที่ ${interview} เรียบร้อย`,
        });
      }
    } catch (err) {
      return err;
    }
  };

  const apply = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      is_confirm: confirm,
      register_id: test2.register_id,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}acceptdeny`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        setCon("");
        if (confirm === true) {
          Swal.fire({
            icon: "success",
            text: `ท่านได้รับ ${test2.fname} เข้าทำงานเรียบร้อย`,
          });
        }
        if (confirm === false) {
          Swal.fire({
            icon: "success",
            text: `ท่านได้ปฏิเสธ ${test2.fname} เข้าทำงานเรียบร้อย`,
          });
        }
      }
    } catch (err) {
      return err;
    }
  };

  const getprovinces = async () => {
    try {
      var response = await fetch(`${ENDPOINT_URL}allprovinces`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
      });
      var data = await response.json();
      let proV = data?.result;
      proV.unshift({
        id: 0,
        code: "0",
        name_th: "จังหวัด",
        name_en: "Provinces",
        geography_id: 0,
      });
      setAll(proV);
    } catch (err) {
      return err;
    }
  };

  const getamperby_provinces = async (value) => {
    let body = {
      province_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}districts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      let allAper = data?.result;
      allAper.unshift({
        id: 0,
        code: "0",
        name_th: "อำเภอ",
        name_en: "Amphures",
        geography_id: 0,
      });
      setAllapm(allAper);
    } catch (err) {
      return err;
    }
  };
  const getdistrict = async (value) => {
    let body = {
      district_code: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}subdistricts`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      let address_district = data?.result;
      address_district.unshift({
        id: 0,
        zip_code: "0",
        name_th: "ตำบล",
        name_en: "address_district",
        district_code: 0,
      });
      setAlldis(address_district);
    } catch (err) {
      return err;
    }
  };

  const getposition = async () => {
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}allpositions`, {
        method: "GET",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
      });
      var data = await response.json();
      setPosi(data.result);
    } catch (err) {
      return err;
    }
  };

  const findworker = async (value) => {
    const jwt = localStorage.getItem("jwt_token");
    // var filter = {};
    // filter = {};
    var address_subdistrict;
    var address_district;
    if (apmm === 0) {
      address_district = "";
    } else {
      address_district = apmer;
    }
    let body = {
      token: jwt,
      filter: {
        address_province,
        address_district,
        address_subdistrict,
        fname: filter,
        position_1: filter2,
      },
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}findworker`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();
      if (data.code === 200) {
        if (data.message === "No data found") {
          Swal.fire({
            title: "ไม่มีข้อมูล",
            confirmButtonText: "ตกลง",
            timer: 2000,
          });
        } else setTest(data.result);
      } else {
        setTest([]);
      }
    } catch (err) {
      return err;
    }
  };

  /* const filterPosition = filteredPosition.filter((position_1)) => */

  const toggle = () => setModal(!modal);
  const toggleclick = () => setModal2(!modal2);
  const togglepo = () => setPositinOpen((prevState) => !prevState);
  const toggledrop = () => setDropdownOpen((prevState) => !prevState);
  const toggleapm = () => setApmOpen((prevState) => !prevState);
  const toggledis = () => setDisOpen((prevState) => !prevState);

  const checkDateseminar = (e) => {
    if (e === "") {
      return "";
    } else {
      return moment(e).format("DD/MM/YYYY");
    }
  };

  console.log(positionID)
  console.log(filter)

  return (
    <div>
      <div>
        <Modal isOpen={modal2} toggle={toggleclick} fullscreen backdrop={false}>
          <ModalHeader>รายละเอียดผู้สมัคร</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="2">
                <img
                  src={avatar}
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
              </Col>
              <Col sm="10">
                <CardTitle
                  tag="h3"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                >
                  {`${test2.fname} ${test2.lname}`}
                </CardTitle>
                <CardTitle
                  tag="h4"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                >
                  ตำแหน่งที่ 1 : {`${test2.position_1}`}
                </CardTitle>
                <CardTitle
                  tag="h5"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                >
                  ตำแหน่งที่ 2 : {`${test2.position_2}`}
                </CardTitle>
                <CardTitle
                  tag="h6"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                >
                  ตำแหน่งที่ 3 : {`${test2.position_3}`}
                </CardTitle>
              </Col>
              <CardTitle
                style={{
                  borderBottom: "2px solid",
                  borderColor: "#EBE9F1",
                  marginTop: 30,
                  marginBottom: 10,
                }}
              ></CardTitle>
            </Row>
            <Row>
              <Col sm="1"></Col>
              <Col sm="5">
                <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                  ข้อมูลส่วนตัว
                </CardTitle>
                <CardText>
                  อายุ : {`${test2.age}`} <br />
                  เพศ : {`${test2.gender}`} <br />
                  ระดับการศึกษา : {`${test2.highest_education}`} <br />
                  เชื้อชาติ : {`${test2.nationality}`} <br />
                  สัญชาติ : {`${test2.nationality}`}
                </CardText>
                <CardText>
                  ที่อยู่ปัจจุบัน : {`${test2.current_address}`}{" "}
                  {`${test2.address_subdistrict}`} {`${test2.address_district}`}{" "}
                  {`${test2.address_province}`} {`${test2.address_zipcode}`}
                </CardText>
                <CardText>
                  ช่องทางการติดต่อ <br />
                  เบอร์โทรศัพท์ : {`${test2.tel}`} <br />
                </CardText>
              </Col>
              <Col sm="6">
                <CardText>
                  ประสบการณ์การทำงาน 1 <br />
                  ตำแหน่ง :{" "}
                  {`${
                    test2.experience_1 === undefined ? "" : test2.experience_1
                  }`}{" "}
                  <br />
                  สถานที่ :
                  {`${
                    test2.experience_1_place === undefined
                      ? ""
                      : test2.experience_1_place
                  }`}
                  <br />
                  รายละเอียด :{" "}
                  {`${
                    test2.experience_1_detail === undefined
                      ? ""
                      : test2.experience_1_detail
                  }`}
                  <br />
                </CardText>
                <CardText>
                  ประสบการณ์การทำงาน 2<br />
                  ตำแหน่ง :{" "}
                  {`${
                    test2.experience_2 === undefined ? "" : test2.experience_2
                  }`}{" "}
                  <br />
                  สถานที่{" "}
                  {`${
                    test2.experience_2_place === undefined
                      ? ""
                      : test2.experience_2_place
                  }`}
                  <br />
                  รายละเอียด :{" "}
                  {`${
                    test2.experience_2_detail === undefined
                      ? ""
                      : test2.experience_2_detail
                  }`}
                  <br />
                </CardText>
                <CardText>
                  ประวัติการอบรม 1
                  <br />
                  วันที่ : {checkDateseminar(test2.seminar_course_date_1)}
                  <br />
                  หัวข้ออบรม :{" "}
                  {`${
                    test2.seminar_course_1 === undefined
                      ? ""
                      : test2.seminar_course_1
                  }`}
                </CardText>
                <CardText>
                  ประวัติการอบรม 2
                  <br />
                  วันที่ : {checkDateseminar(test2.seminar_course_date_2)}
                  <br />
                  หัวข้ออบรม :{" "}
                  {`${
                    test2.seminar_course_2 === undefined
                      ? ""
                      : test2.seminar_course_2
                  }`}
                </CardText>
                <Row>
                  <Col lg="8" md="7"></Col>
                  <Col lg="4" md="5">
                    {/* <Button
                      color="secondary"
                      onClick={toggle}
                      style={{ fontSize: "15px" }}
                      outline
                      block
                    >
                      ประกาศนียบัตร
                    </Button> */}
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                      <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                          Do Something
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  <Col md="6" lg="8">
                    <Label>นัดวันสัมภาษณ์</Label>
                    <Input
                      name="birdDate"
                      placeholder="DD/MM/YY"
                      type="date"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInter(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6" lg="4">
                    <Button
                      style={{
                        background: "#7367F0",
                        marginTop: "30px",
                        border: "0px",
                      }}
                      block
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        postinterview();
                      }}
                    >
                      นัดสัมภาษณ์
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm="1"></Col>
              <Col sm="5">
                <Row>
                  <Col
                    sm="12"
                    style={{ marginTop: "30px" }}
                    className="d-lg-none d-md-none d-block"
                  >
                    <p>หมายเหตุ</p>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      placeholder="หมายเหตุ"
                    />
                  </Col>
                  <Col
                    sm="12"
                    style={{ marginTop: "-70px" }}
                    className="d-lg-block d-md-block d-none"
                  >
                    <Label>หมายเหตุ</Label>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      placeholder="หมายเหตุ"
                    />
                  </Col>
                  {/*    <Col lg="6">
                    <Button
                      style={{
                        background: "#7367F0",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      disabled={interview === "" ? true : false}
                      block
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        setCon(true);
                        // apply();
                      }}
                    >
                      รับเป็นพนักงาน
                    </Button>
                  </Col>
                  <Col lg="6">
                    <Button
                      style={{
                        background: "#7367F0",
                        marginTop: "15px",
                        border: "0px",
                      }}
                      disabled={interview === "" ? true : false}
                      block
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        setCon(false);
                        // apply();
                      }}
                    >
                      ปฏิเสธการรับเข้า
                    </Button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleclick}>
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
      <div className="container" style={{ marginTop: "90px" }}>
        <Card
          body
          style={{
            border: "0px",
            marginTop: "20px",
            backgroundColor: "#49C5B1",
            // height: "300px",
            marginBottom: "15px",
          }}
          align="center"
        >
          {/* <CardImg
                                alt="Card image cap"
                                src={banner}
                                style={{
                                    height: '100%',
                                }}
                            /> */}

          <CardTitle
            className="d-md-block d-lg-block d-none"
            tag="h3"
            style={{ color: "#FFF" }}
          >
            ค้นหาผู้สมัคร
          </CardTitle>
          <CardTitle
            className="d-md-none d-lg-none"
            tag="h3"
            style={{ color: "#FFF", marginTop: "5px" }}
          >
            ค้นหางาน
          </CardTitle>
          <CardText
            className="d-md-block d-lg-block d-none"
            style={{ color: "#FFF", fontSize: "18px" }}
          >
            พิมประเภทงานเพื่อค้นหางานที่ชอบ
          </CardText>
          <CardText
            className="d-md-none d-lg-none"
            style={{ color: "#FFF", fontSize: "14px" }}
          >
            พิมประเภทงานเพื่อค้นหางานที่ชอบ
          </CardText>

          <Row>
            <Col lg="3" md="1" xs="1"></Col>
            <Col lg="7" md="10" xs="11">
              <Row>
                <Input
                  className="d-none d-md-block d-lg-none"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "70%", marginRight: 20 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Input
                  className="d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "65%", marginRight: 10 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Input
                  className="d-none d-lg-block"
                  id="exampleSearch"
                  name="search"
                  placeholder="search"
                  type="search"
                  style={{ width: "70%", marginRight: 20 }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                />
                <Button
                  className="d-none d-lg-block"
                  style={{ width: "20%" }}
                  color="success"
                  onClick={() => {
                    findworker();
                  }}
                >
                  Search
                </Button>
                <Button
                  className="d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none"
                  style={{ width: "25%" }}
                  color="success"
                  onClick={() => {
                    findworker();
                  }}
                >
                  Search
                </Button>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="2" className="d-none d-lg-block"></Col>
            <Col md="2" className="d-md-block d-lg-none d-none"></Col>
            <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
              <Dropdown
                isOpen={positionOpen}
                toggle={togglepo}
                direction={direction}
                width="10%"
              >
                <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                  {positionID === "" ? "ตำแหน่ง" : positionID}
                </DropdownToggle>
                <DropdownMenu
                  style={{ width: "80%" }}
                >
                    <DropdownItem
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    setPosition("เลือกตำแหน่ง");
                    setFilter2("");
                  }}
                >
                  เลือกตำแหน่ง
                </DropdownItem>
                {posi.map((item) => (
                  <DropdownItem
                  style={{fontSize: "14px"}}
                    onClick={(e) => {
                      setFilter2(item.position_name);
                      setPosition(item.position_name);
                    }}
                  >
                    {item.position_name}
                  </DropdownItem>
                ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggledrop}
                direction={direction}
                width="10%"
              >
                <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                  {address_province === "" ? "จังหวัด" : address_province}
                </DropdownToggle>
                <DropdownMenu
                  {...args}
                  style={{ width: "80%", height: "550%", overflow: "scroll" }}
                >
                  {allprovinces.map((item) => (
                    <DropdownItem
                      onClick={(e) => {
                        setPro(item.name_th);
                        getamperby_provinces(item.code);
                        setApm("");
                        setDis("");
                      }}
                    >
                      {item.name_th}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
              <Dropdown
                isOpen={apmOpen}
                toggle={toggleapm}
                direction={direction}
              >
                <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                  {apmer === "" ? "อำเภอ" : apmer}
                </DropdownToggle>
                <DropdownMenu
                  {...args}
                  style={{ width: "80%", height: "550%", overflow: "scroll" }}
                >
                  {allapmer.map((item) => (
                    <DropdownItem
                      onClick={(e) => {
                        setApm(item.name_th);
                        getdistrict(item.code);
                        setDis("");
                      }}
                    >
                      {item.name_th}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col lg="2" md="12" xs="12" style={{ marginTop: "15px" }}>
              <Dropdown
                isOpen={disOpen}
                toggle={toggledis}
                direction={direction}
              >
                <DropdownToggle caret style={{ width: "80%", fontSize: '14px' }}>
                  {address_district === "" ? "ตำบล" : address_district}
                </DropdownToggle>
                <DropdownMenu
                  {...args}
                  style={{ width: "80%", height: "550%", overflow: "scroll" }}
                >
                  {alldistrict.map((item) => (
                    <DropdownItem
                      onClick={(e) => {
                        setDis(item.name_th);
                      }}
                    >
                      {item.name_th}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Card>
        <Row className="m-auto align-self-center">
          {test.length === undefined ? (
            <div></div>
          ) : (
            test.map((work) => (
              <Col lg="4" className="d-none d-xl-block">
                <li
                  key={work.worker_id}
                  style={{ listStyleType: "none", marginBottom: "10px" }}
                >
                  <Card body>
                    <a
                      onClick={() => {
                        setTest2(work);
                        toggleclick();
                      }}
                    >
                      <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                        {work.position_1}
                      </CardTitle>
                      <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                        {work.fname} {work.lname}
                      </CardTitle>
                    </a>
                  </Card>
                </li>
              </Col>
            ))
          )}

          {/* mobile */}
          <Col md="12" xs="12" className="d-xl-none">
            {test.length == undefined ? (
              <div></div>
            ) : (
              test.map((works) => (
                <li
                  key={works.worker_id}
                  style={{ listStyleType: "none", marginBottom: "10px" }}
                >
                  <Card body>
                    <a
                      onClick={() => {
                        setTest2(works);
                        toggleclick();
                      }}
                    >
                      <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                        {works.position_1}
                      </CardTitle>
                      <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                        {works.fname} {works.lname}
                      </CardTitle>
                    </a>
                  </Card>
                </li>
              ))
            )}
          </Col>
          {/* mobile */}
          {/* <Col sm="9" className="d-none d-lg-block">
            {test2.worker_id === 0 ? (
              <></>
            ) : (
              <Card body>
                <CardTitle tag="h5">รายละเอียดผู้สมัคร</CardTitle>
                <CardTitle
                  style={{ borderBottom: "2px solid", borderColor: "#EBE9F1" }}
                />
                <Row>
                  <Col sm="2">
                    <img
                      src={mon}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </Col>
                  <Col sm="10">
                    <CardTitle tag="h3" style={{ fontWeight: "bold" }}>
                      {`${test2.fname} ${test2.lname}`}
                    </CardTitle>
                    <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                      ตำแหน่งที่ 1 : {`${test2.position_1}`}
                    </CardTitle>
                    <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                      ตำแหน่งที่ 2 : {`${test2.position_2}`}
                    </CardTitle>
                    <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                      ตำแหน่งที่ 3 : {`${test2.position_3}`}
                    </CardTitle>
                  </Col>
                  <CardTitle
                    style={{
                      borderBottom: "2px solid",
                      borderColor: "#EBE9F1",
                    }}
                  ></CardTitle>
                </Row>
                <Row>
                  <Col sm="1"></Col>
                  <Col sm="5">
                    <CardTitle tag="h6" style={{ fontWeight: "bold" }}>
                      ข้อมูลส่วนตัว
                    </CardTitle>
                    <CardText>
                      อายุ : {`${test2.age}`} <br />
                      เพศ : {`${test2.gender}`} <br />
                      ระดับการศึกษา : {`${test2.highest_Education}`} <br />
                      เชื้อชาติ : {`${test2.nationality}`} <br />
                      สัญชาติ : {`${test2.nationality}`}
                    </CardText>
                    <CardText>
                      ที่อยู่ปัจจุบัน : {`${test2.current_address}`}{" "}
                      {`${test2.address_subdistrict}`}{" "}
                      {`${test2.address_district}`}{" "}
                      {`${test2.address_province}`} {`${test2.address_zipcode}`}
                    </CardText>
                    <CardText>
                      ช่องทางการติดต่อ <br />
                      เบอร์โทรศัพท์ : {`${test2.tel}`} <br />
                      E-mail : {`${test2.email}`}
                    </CardText>
                  </Col>
                  <Col sm="6">
                    <CardText>
                      ประสบการณ์การทำงาน 1 <br />
                      ตำแหน่ง : {`${test2.experience_1}`} <br />
                      สถานที่ : {`${test2.experience_1_place}`}
                      <br />
                      รายละเอียด : {`${test2.experience_1_detail}`}
                      <br />
                    </CardText>
                    <CardText>
                      ประสบการณ์การทำงาน 2<br />
                      ตำแหน่ง : {`${test2.experience_2}`} <br />
                      สถานที่ {`${test2.experience_2_place}`}
                      <br />
                      รายละเอียด : {`${test2.experience_2_detail}`}
                      <br />
                    </CardText>
                    <CardText>
                      ประวัติการอบรม 1
                      <br />
                      วันที่ : {`${test2.seminar_course_date_1}`}
                      <br />
                      หัวข้ออบรม : {`${test2.seminar_course_1}`}
                    </CardText>
                    <CardText>
                      ประวัติการอบรม 2
                      <br />
                      วันที่ : {`${test2.seminar_course_date_2}`}
                      <br />
                      หัวข้ออบรม : {`${test2.seminar_course_2}`}
                    </CardText>
                    <Row>
                      <Col lg="8" md="7"></Col>
                      <Col lg="4" md="5">
                        <Button
                          color="secondary"
                          onClick={toggle}
                          style={{ fontSize: "15px" }}
                          outline
                          block
                        >
                          ประกาศนียบัตร
                        </Button>
                        <Modal isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                          <ModalBody>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={toggle}>
                              Do Something
                            </Button>{" "}
                            <Button color="secondary" onClick={toggle}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Col>
                      <Col md="6" lg="8">
                        <Label>นัดวันสัมภาษณ์</Label>
                        <Input
                          name="birdDate"
                          placeholder="DD/MM/YY"
                          type="date"
                          onClick={(e) => {
                            setInter(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <Button
                          style={{
                            background: "#7367F0",
                            marginTop: "30px",
                            border: "0px",
                          }}
                          block
                          type="submit"
                          value="Submit"
                          onClick={() => {
                            postinterview();
                          }}
                        >
                          นัดสัมภาษณ์
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm="1"></Col>
                  <Col sm="5">
                    <Row>
                      <Col
                        sm="12"
                        style={{ marginTop: "30px" }}
                        className="d-lg-none d-md-none d-block"
                      >
                        <p>หมายเหตุ</p>
                        <Input
                          id="exampleText"
                          name="text"
                          type="textarea"
                          placeholder="หมายเหตุ"
                        />
                      </Col>
                      <Col
                        sm="12"
                        style={{ marginTop: "-70px" }}
                        className="d-lg-block d-md-block d-none"
                      >
                        <Label>หมายเหตุ</Label>
                        <Input
                          id="exampleText"
                          name="text"
                          type="textarea"
                          placeholder="หมายเหตุ"
                        />
                      </Col>
                      <Col lg="6">
                        <Button
                          style={{
                            background: "#7367F0",
                            marginTop: "15px",
                            border: "0px",
                          }}
                          disabled={interview === "" ? true : false}
                          block
                          type="submit"
                          value="Submit"
                          onClick={() => {
                            setCon(true);
                            apply();
                          }}
                        >
                          รับเป็นพนักงาน
                        </Button>
                      </Col>
                      <Col lg="6">
                        <Button
                          style={{
                            background: "#7367F0",
                            marginTop: "15px",
                            border: "0px",
                          }}
                          disabled={interview === "" ? true : false}
                          block
                          type="submit"
                          value="Submit"
                          onClick={() => {
                            setCon(false);
                            apply();
                          }}
                        >
                          ปฏิเสธการรับเข้า
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )} 
          </Col>*/}
        </Row>
      </div>
    </div>
  );
};

export default FinderWorkerPage;
