import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Badge,
  ListGroupItem,
  ListGroup,
  CardHeader,
} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState, useEffect } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { TrySharp } from "@mui/icons-material";

//check token expiration

/* const work = [
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-----------",
    status: 1,
  },
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-------",
    status: 1,
  },
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-------",
    status: 2,
  },
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-------",
    status: 1,
  },
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-------",
    status: 1,
  },
  {
    job: "พนักงานประจำร้าน",
    banch: "ห้วยขวาง",
    date: "28 เม.ย 2022",
    salary: "xxxxx - xxxxx",
    getCount: "3",
    register: "5",
    note: "-------",
    status: 3,
  },
]; */

const options = ["ซ่อน"];

const ITEM_HEIGHT = 48;

const WorkerHistoryPage = () => {
  const getJWT = () => {
    const jwt = localStorage.getItem("jwt_token");
    setJWT(jwt);
  };

  const [JWT, setJWT] = useState("");
  const [annhis, setAnnhis] = useState([]);
  const [filter, setfiler] = useState("");
  const [publics, setPublic] = useState("");
  const [privates, setPrivate] = useState("");
  const [completed, setCompleted] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    getJWT();
    gethistory();
  }, []);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
    Swal.fire({
      title: "infomation",
      text: "ประกาศนี้จะถูกซ่อน ผู้สมัครจะไม่เห็นประกาศนี้อีกต่อไป ยืนยัน/ยกเลิก",
      icon: "warning",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        hideann();
        Swal.fire({
          title: "success",
          text: "ประกาศนี้ถูกซ่อน",
          icon: "success",
          confirmButtonText: "ยืนยัน",
        });
      }
    });
  };

  const gethistory = async (value = "") => {
    let test = "";
    if (filter === "") {
      test = "";
    } else {
      test = filter;
    }
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
      filter: value,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}selectannouncement`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      var data = await response.json();

      setAnnhis(data.result);

      if (data.result.announcement_status === "public") {
        console.log(data.result.announcement_status);
      }
    } catch (err) {
      return err;
    }
  };

  const handlefilter = (value) => {
    setTimeout(() => {
      gethistory();
    }, 3000);
  };

  const hideann = async () => {
    const jwt = localStorage.getItem("jwt_token");
    let body = {
      token: jwt,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}hideannounce`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="container">
      <Card body style={{ marginTop: "90px", marginBottom: "15px" }}>
        <Row>
          <Col sm="8" lg="10">
            <CardText style={{ color: "#5E5873" }}>ประวัติการลงประกาศ</CardText>
          </Col>
          <Col sm="4" lg="2">
            <Button
              color="primary"
              style={{
                marginTop: "15px",
                fontSize: "15px",
                marginBottom: "15px",
              }}
              href="/jobFinder_c"
              block
            >
              สร้างประกาศหางาน
            </Button>
          </Col>
        </Row>

        <ListGroupItem>
          <Button
            style={{
              backgroundColor: "#7367F0",
              borderRadius: "25px",
              display: "inline-block",
              paddingTop: "3px",
              paddingBottom: "3px",
              marginRight: "15px",
              color: "#000",
              fontSize: "12px",
            }}
            onClick={(e) => {
              setAnnhis([]);
              setTimeout(() => {
                gethistory("");
              }, 1000);
            }}
          >
            โพสทั้งหมด
          </Button>

          <Button
            style={{
              background: "rgba(186, 191, 199, 0.12)",
              borderRadius: "25px",
              display: "inline-block",
              paddingTop: "3px",
              paddingBottom: "3px",
              marginRight: "15px",
              color: "#000",
              fontSize: "12px",
            }}
            onClick={(e) => {
              setAnnhis([]);
              setTimeout(() => {
                gethistory("private");
              }, 1000);
            }}
            /* >
            ซ่อน
          </Button>
          <Button
            style={{
              backgroundColor: "#AEFFC0",
              borderRadius: "25px",
              display: "inline-block",
              paddingTop: "3px",
              paddingBottom: "3px",
              marginRight: "15px",
              color: "#000",
              fontSize: "12px",
            }}
            onClick={(e) => {
              setAnnhis([]);
              setTimeout(() => {
                gethistory("completed");
              }, 1000);
            }} */
          >
            ผู้สมัครครบ
          </Button>
          <Button
            style={{
              backgroundColor: "#CFDFFE",
              borderRadius: "25px",
              display: "inline-block",
              paddingTop: "3px",
              paddingBottom: "3px",
              marginRight: "15px",
              color: "#000",
              fontSize: "12px",
            }}
            onClick={(e) => {
              setAnnhis([]);
              setTimeout(() => {
                gethistory("public");
              }, 1000);
            }}
          >
            ผู้สมัครยังไม่ครบ
          </Button>
        </ListGroupItem>
        {annhis.length === undefined ? (
          <div></div>
        ) : (
          annhis.map(
            (data) => (
              console.log(data),
              (
                <li
                  key={data.announcement_status}
                  style={{ listStyleType: "none" }}
                >
                  {
                    data.announcement_status === "public" ? (
                      <Card
                        style={{
                          marginTop: "15px",
                          background: "rgba(64, 128, 253, 0.25)",
                          border: "none",
                        }}
                      >
                        <Row>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText tag="h5">
                              {data.position_id === 1
                                ? "พนักงานประจำสาขา"
                                : data.position_id === 12
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 3
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 4
                                ? "Chef Head"
                                : data.position_id === 5
                                ? "Sales Man"
                                : ""}
                            </CardText>
                            <CardText>
                              ร้านไก่ย่างห้าดาว สาขา {data.banch}
                              <br />
                              ประกาศไปเมื่อ{" "}
                              {moment(data.posting_date).format("DD/MM/YYYY")}
                            </CardText>
                          </Col>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>เงินเดือน : {data.payroll}</CardText>
                            <CardText>
                              จำนวนที่รับ : {data.position_required}
                            </CardText>
                          </Col>
                          <Col
                            sm="2"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>
                              ผู้สมัคร : {data.position_recruited}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText style={{ padding: "16px" }}>
                              <Row>
                                <Col lg="10" md="9" xs="10">
                                  หมายเหตุ : {data.note}
                                </Col>
                                {/* <Col lg="2" md="3" xs="2">
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? "long-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: "20ch",
                                    },
                                  }}
                                >
                                  {options.map((option) => (
                                    <MenuItem
                                      key={option}
                                      selected={option === "Pyxis"}
                                      onClick={handleClose}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </Col> */}
                                <Col sm="9"></Col>
                                <Col sm="3">
                                  {/* <Button
                              onClick={(e) => {
                                console.log(e);
                              }}
                            >
                              ซ่อน
                            </Button> */}
                                </Col>
                              </Row>
                            </CardText>
                          </Col>
                        </Row>
                      </Card>
                    ) : data.announcement_status === "private" ? (
                      <Card
                        style={{
                          marginTop: "10px",
                          background: "rgba(186, 191, 199, 0.12)",
                          border: "none",
                        }}
                      >
                        <Row>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText tag="h5">
                              {data.position_id === 1
                                ? "พนักงานประจำสาขา"
                                : data.position_id === 12
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 3
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 4
                                ? "Chef Head"
                                : data.position_id === 5
                                ? "Sales Man"
                                : ""}
                            </CardText>
                            <CardText>
                              ร้านไก่ย่างห้าดาว สาขา {data.banch}
                              <br />
                              ประกาศไปเมื่อ{" "}
                              {moment(data.posting_date).format("DD/MM/YYYY")}
                            </CardText>
                          </Col>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>เงินเดือน : {data.payroll}</CardText>
                            <CardText>
                              จำนวนที่รับ : {data.position_required}
                            </CardText>
                          </Col>
                          <Col
                            sm="2"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>
                              ผู้สมัคร : {data.position_recruited}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText style={{ padding: "16px" }}>
                              <Row>
                                <Col lg="10" md="9" xs="10">
                                  หมายเหตุ : {data.note}
                                </Col>
                                {/* <Col lg="2" md="3" xs="2">
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: "20ch",
                                      },
                                    }}
                                  >
                                    {options.map((option) => (
                                      <MenuItem
                                        key={option}
                                        selected={option === "Pyxis"}
                                        onClick={handleClose}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Col> */}
                              </Row>
                            </CardText>
                          </Col>
                        </Row>
                      </Card>
                    ) : data.announcement_status === "completed" ? (
                      <Card
                        style={{
                          marginTop: "10px",
                          background: "#AEFFC0",
                          border: "none",
                        }}
                      >
                        <Row>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText tag="h5">
                              {data.position_id === 1
                                ? "พนักงานประจำสาขา"
                                : data.position_id === 12
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 3
                                ? "พนักงานขายบริษัท"
                                : data.position_id === 4
                                ? "Chef Head"
                                : data.position_id === 5
                                ? "Sales Man"
                                : ""}
                            </CardText>
                            <CardText>
                              ร้านไก่ย่างห้าดาว สาขา {data.banch}
                              <br />
                              ประกาศไปเมื่อ{" "}
                              {moment(data.posting_date).format("DD/MM/YYYY")}
                            </CardText>
                          </Col>
                          <Col
                            sm="3"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>เงินเดือน : {data.payroll}</CardText>
                            <CardText>
                              จำนวนที่รับ : {data.position_required}
                            </CardText>
                          </Col>
                          <Col
                            sm="2"
                            style={{
                              borderRight: "2px solid",
                              borderRightColor: "#BEBEBE",
                              padding: "16px",
                            }}
                          >
                            <CardText>
                              ผู้สมัคร : {data.position_recruited}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText style={{ padding: "16px" }}>
                              <Row>
                                <Col lg="10" md="9" xs="10">
                                  หมายเหตุ : {data.note}
                                </Col>
                                {/* <Col lg="2" md="3" xs="2">
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: "20ch",
                                      },
                                    }}
                                  >
                                    {options.map((option) => (
                                      <MenuItem
                                        key={option}
                                        selected={option === "Pyxis"}
                                        onClick={handleClose}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Col> */}
                              </Row>
                            </CardText>
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      <></>
                    ) /* (
              <Card
                style={{
                  marginTop: "10px",
                  backgroundColor: "#AEFFC0",
                  border: "none",
                }}
              >
                <Row>
                  <Col
                    sm="3"
                    style={{
                      borderRight: "2px solid",
                      borderRightColor: "#BEBEBE",
                      padding: "16px",
                    }}
                  >
                    <CardText tag="h5">{data.job}</CardText>
                    <CardText>
                      ร้านไก่ย่างห้าดาว สาขา {data.banch}
                      <br />
                      สมัครไปเมื่อ{" "}
                      {moment(data.posting_date).format("DD/MM/YYYY")}
                    </CardText>
                  </Col>
                  <Col
                    sm="3"
                    style={{
                      borderRight: "2px solid",
                      borderRightColor: "#BEBEBE",
                      padding: "16px",
                    }}
                  >
                    <CardText>เงินเดือน : {data.payroll}</CardText>
                    <CardText>จำนวนที่รับ : {data.position_required}</CardText>
                  </Col>
                  <Col
                    sm="2"
                    style={{
                      borderRight: "2px solid",
                      borderRightColor: "#BEBEBE",
                      padding: "16px",
                    }}
                  >
                    <CardText>ผู้สมัคร : {data.position_recruited}</CardText>
                  </Col>
                  <Col sm="4">
                    <CardText style={{ padding: "16px" }}>
                      <Row>
                        <Col sm="10">หมายเหตุ : {data.note}</Col>
                        <Col sm="2">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                              },
                            }}
                          >
                            {options.map((option) => (
                              <MenuItem
                                key={option}
                                selected={option === "Pyxis"}
                                onClick={handleClose}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </Col>
                      </Row>
                    </CardText>
                  </Col>
                </Row>
              </Card>
            ) */
                  }
                </li>
              )
            )
          )
        )}
      </Card>
    </div>
  );
};

export default WorkerHistoryPage;
