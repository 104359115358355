import React from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import avatar from "../avatar.webp";

const JobFinder_T_MPage = () => {
  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <Card body>
        <Row>
          <Col sm="2">
            <img
              src={avatar}
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "50%",
                marginBottom: "15px",
              }}
            />
          </Col>
          <Col>
            <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
              ร้าน ไก่ย่างห้าดาว
            </CardTitle>
            <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
              สาขา ห้วยขวาง
            </CardTitle>
            <CardText>
              ตำแหน่งที่ต้องการ : คนทำอาหาร
              <br />
              {/* </CardText> */}
              <Label>ที่อยู่ร้าน : </Label>
              <a
                href="https://www.google.co.th/maps/place/SI-AM+Bag/@13.8038886,100.5489339,14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06"
                target="_blank"
                className="text-decoration"
                style={{ marginLeft: "5px" }}
              >
                ห้วยขวาง ซอย 123
              </a>
            </CardText>
          </Col>
          <CardTitle
            style={{
              borderBottom: "2px solid",
              marginTop: "15px",
              marginBottom: "15px",
              borderBottomColor: "#EBE9F1",
            }}
          />
        </Row>
        <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
          ร้าน ไก่ย่างห้าดาว
        </CardTitle>
        <CardText>
          หน้าที่การรับผิดชอบหลักของตำแหน่งนี้คือ
          การทำตามออเดอร์ที่ลูกค้าสั่งมาอย่างถูกต้อง
          มีความรู้ความเข้าใจเกี่ยวกับการทำอาหารอย่างถูกสุขลักษณะ และ
          มีความรับผิดชอบต่อหน้าที่ของตนเอง
          เพื่อตอบสนองความต้องการของลูกค้าในร้าน
        </CardText>
        <Row>
          <Col sm="5">
            <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
              คุณสมบัติงาน
            </CardTitle>
            <CardText>
              รูปแบบงาน : งานประจำ <br />
              จำนวนที่รับ : 3 ตำแหน่ง <br />
              สถานที่ปฏิบัติงาน : กรุงเทพมหานคร(เขตห้วยขวาง)
              <br />
              เงินเดือน(บาท) : xxxxx - xxxxxx
              <br />
              เวลาทำงาน : สามารถเลือกเป็นกะ
              <br />
              เวลาทำงานอื่น : ไม่ระบุ
              <br />
            </CardText>
            <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
              หน้าที่ความรับผิดชอบ
            </CardTitle>
            <CardText>
              -มีทางเทรนนิ่งสอนรายละเอียดตลอดการทำงาน
              <br />
              -แนะนำการขายกับลูกค้า
              <br />
              -มีความรับผิดชอบในการคิดเงิน
              <br />
            </CardText>
            <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
              คุณสมบัติ
            </CardTitle>
            <CardText>
              เพศ : ชาย , หญิง
              <br />
              อายุ(ปี) : 25 - 36
              <br />
              ระดับการศึกษา : ปวส. - ปริญญาตรี
              <br />
              ประสบการณ์(ปี) : 1 - 3<br />
              อื่นๆ : ยินดีรับนักศึกษาจบใหม่
              <br />
              ,ยินดีรับผู้ไม่มีประสบการณ์
              <br />
            </CardText>
          </Col>
          <Col sm="7">
            <img
              src={avatar}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "6%",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="10"></Col>
          <Col sm="2">
            <Button
              style={{
                background: "#49C5B1",
                marginTop: "15px",
                border: "0px",
              }}
              block
              type="submit"
              value="Submit"
            >
              ยื่นใบสมัคร
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default JobFinder_T_MPage;
