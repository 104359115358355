import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardBody,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import avatar from "../avatar.webp";
import map from "../maps.png";
import moment from "moment/moment";
import { ENDPOINT_URL } from "../../config/app-config";

const history = [
  {
    job: "พนักงานประจำร้าน",
    status: "รอสัมภาษณ์",
    date: "28 ก.ค. 2022",
    branch: "ห้วยขวาง",
    inter: "30 ก.ค. 2022",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "ส่งใบสมัคร",
    date: "28 ก.ค. 2022",
    branch: "บางกะปิ",
    inter: "-",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "รอสัมภาษณ์",
    date: "28 ก.ค. 2022",
    branch: "รัชดา",
    inter: "30 ก.ค. 2022",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "ไม่ผ่านการคัดเลือก",
    date: "28 ก.ค. 2022",
    branch: "ลาดพร้าววังหิน",
    inter: "-",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "รอสัมภาษณ์",
    date: "28 ก.ค. 2022",
    branch: "พระรามเก้า",
    inter: "30 ก.ค. 2022",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "ไม่ผ่านการคัดเลือก",
    date: "28 ก.ค. 2022",
    branch: "ประดิษฐ์มนูธรรม",
    inter: "-",
  },
  {
    job: "พนักงานประจำร้าน",
    status: "ส่งใบสมัคร",
    date: "28 ก.ค. 2022",
    branch: "ประเสริฐมนูกิจ",
    inter: "-",
  },
];

const DetialPage = (...args) => {
  const [detail, setDetail] = useState([]);
  const [detail2, setDetail2] = useState({});
  useEffect(() => {
    getdetail();
  }, []);

  const getdetail = async () => {
    const jwt = localStorage.getItem("jwt_token");
    try {
      var response = await fetch(`${ENDPOINT_URL}workerhistorydetails`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ token: jwt }),
      });

      var data = await response.json();
      setDetail(data.result);
    } catch (err) {
      return err;
    }
  };

  const checkDate = (e) => {
    if (e === null) {
      return "";
    } else {
      return moment(e).format("DD/MM/YYYY");
    }
  };
  const checkDateapply = (e) => {
    if (e === "") {
      return "";
    } else {
      return moment(e).format("DD/MM/YYYY");
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    console.log(detail2),
    console.log(detail),
    (
      <div className="container" style={{ marginTop: "90px" }}>
        <Row className="m-auto align-self-center">
          <Col sm="3" className="d-lg-block d-none">
            {detail.map((his) => (
              <li key={his.announcement_id} style={{ listStyleType: "none" }}>
                <Card
                  body
                  style={{
                    border: "0px",
                    backgroundColor: "rgba(186, 191, 199, 0.12)",
                    opacity: "Color/Light",
                    marginTop: "15px",
                  }}
                >
                  <a
                    onClick={() => {
                      setDetail2(his);
                    }}
                  >
                    <h5 style={{ color: "#5E5873", fontWeight: "bold" }}>
                      {his.position === ""
                        ? "ได้รับข้อเสนอจากร้าน"
                        : his.position}
                    </h5>
                    <p>
                      ร้าน {his.franchise_name} สาขา {his.franchise_branchname}
                      <br />
                      สถานะ : {his.register_status}
                      <br />
                      สมัครไปเมื่อ : {checkDateapply(his.register_date)}
                      <br /> วันนัดสัมภาษณ์ : {checkDate(his.interview_date)}
                      {/* <br /> <a href="/detail">ดูรายละเอียดเพิ่มเติม</a> */}
                    </p>
                  </a>
                </Card>
              </li>
            ))}
          </Col>
          <Col
            sm="9"
            style={{ marginTop: "15px" }}
            className="d-lg-block d-none"
          >
            <Form inline>
              <Card body className="d-lg-block d-none">
                <Row>
                  <Col sm="2">
                    <img
                      src={avatar}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </Col>
                  <Col>
                    <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                      ร้าน {detail2.franchise_name}{" "}
                      {detail2.franchise_branchname}
                    </CardTitle>
                    <CardTitle
                      tag="h4"
                      style={{ fontWeight: "bold" }}
                    ></CardTitle>
                    <CardText>
                      ตำแหน่งที่ต้องการ : {detail2.position}
                      <br />
                      {/* </CardText> */}
                      <Label>ที่อยู่ร้าน : </Label>
                      <a
                        href="https://www.google.co.th/maps/place/SI-AM+Bag/@13.8038886,100.5489339,14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06"
                        target="_blank"
                        className="text-decoration"
                        style={{ marginLeft: "5px" }}
                      >
                        {detail2.franchise_subdistrict}{" "}
                        {detail2.franchise_district}{" "}
                        {detail2.franchise_province}
                      </a>
                    </CardText>
                  </Col>
                  <CardTitle
                    style={{
                      borderBottom: "2px solid",
                      marginTop: "15px",
                      marginBottom: "15px",
                      borderBottomColor: "#EBE9F1",
                    }}
                  />
                </Row>
                <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                  ร้าน {detail2.franchise_name} {detail2.franchise_branchname}
                </CardTitle>
                <CardText style={{ whitespace: "pre-wrap" }}></CardText>
                <Row>
                  <Col sm="5">
                    <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                      คุณสมบัติงาน
                    </CardTitle>
                    <CardText>
                      รูปแบบงาน : {detail2.position} <br />
                      จำนวนที่รับ : {detail2.position_required} <br />
                      สถานที่ปฏิบัติงาน : {detail2.franchise_subdistrict}{" "}
                      {detail2.franchise_province}
                      <br />
                      เงินเดือน(บาท) : {detail2.payroll}
                      <br />
                      เวลาทำงาน :{" "}
                      {detail2.franchise_name === undefined
                        ? ""
                        : "สามารถเลือกเป็นกะ"}
                      <br />
                      เวลาทำงานอื่น :{" "}
                      {detail2.franchise_name === undefined ? "" : "ไม่ระบุ"}
                      <br />
                    </CardText>
                    <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                      หน้าที่ความรับผิดชอบ
                    </CardTitle>
                    <CardText style={{ whiteSpace: "pre-wrap", width: 300 }}>
                      {detail2.responsibilities}
                    </CardText>
                    {/* <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                      คุณสมบัติ
                    </CardTitle>
                    <CardText>{detail2.job_description}</CardText> */}
                  </Col>
                  <Col sm="7" align="center" style={{ marginTop: "30px" }}>
                    <Row>
                      <Col sm="2"></Col>
                      <Col sm="6" align="left">
                        <CardText>
                          <Label style={{ fontWeight: "bold" }}>สถานะ :</Label>{" "}
                          {detail2.register_status}
                          <br />
                          {/* 000 */}
                          <Label style={{ fontWeight: "bold" }}>
                            สมัครไปเมื่อ :
                          </Label>{" "}
                          {checkDateapply(detail2.register_date)}
                          {/* {moment(detail2.register_date).format("DD/MM/YYYY")} */}
                          {console.log(detail2.register_date)}
                          <br />
                          <Label style={{ fontWeight: "bold" }}>
                            วันนัดสัมภาษณ์ :
                          </Label>{" "}
                          {checkDate(detail2.interview_date)}
                        </CardText>
                      </Col>
                    </Row>
                    <img
                      src={map}
                      style={{
                        width: "80%",
                        borderRadius: "6%",
                      }}
                    />
                  </Col>
                </Row>
                {/* <Row>
                                <Col sm="6"></Col>
                                <Col sm="6">
                                <CardText>
                                        สถานะ : รอสัมภาษณ์
                                        <br />
                                        สมัครไปเมื่อ : 28 ก.ค. 2022
                                        <br />
                                        วันนัดสัมภาษณ์ : 30 ก.ค. 2022
                                    </CardText>
                                </Col>
                            </Row> */}
              </Card>
            </Form>
          </Col>
        </Row>
        <Col
          style={{ marginTop: "15px" }}
          className="d-sm-block d-md-block d-lg-none"
        >
          {detail.map((his) => (
            <li key={his.announcement_id} style={{ listStyleType: "none" }}>
              <Card
                body
                style={{
                  border: "0px",
                  backgroundColor: "rgba(186, 191, 199, 0.12)",
                  opacity: "Color/Light",
                  marginTop: "15px",
                }}
              >
                <a
                  onClick={() => {
                    setDetail2(his);
                    toggle();
                  }}
                >
                  <h5 style={{ color: "#5E5873", fontWeight: "bold" }}>
                    {his.position === ""
                      ? "ได้รับข้อเสนอจากร้าน"
                      : his.position}
                  </h5>
                  <p>
                    ร้าน {his.franchise_name} สาขา {his.franchise_branchname}
                    <br />
                    สถานะ : {his.register_status}
                    <br />
                    สมัครไปเมื่อ : {checkDateapply(his.register_date)}
                    <br /> วันนัดสัมภาษณ์ : {checkDate(his.interview_date)}
                    {/* <br /> <a href="/detail">ดูรายละเอียดเพิ่มเติม</a> */}
                  </p>
                </a>
              </Card>
            </li>
          ))}
          {/* 000 */}
          <Modal fullscreen isOpen={modal} toggle={toggle} {...args}>
            <ModalHeader toggle={toggle}>
              <Row>
                <Col sm="2">
                  <img
                    src={avatar}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                      marginBottom: 15,
                      marginRight: 150,
                    }}
                  />
                </Col>
                <Col>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    ร้าน {detail2.franchise_name} {detail2.franchise_branchname}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    ตำแหน่งที่ต้องการ : {detail2.position}
                    <br />
                    <Label>ที่อยู่ร้าน : </Label>
                    <a
                      href="https://www.google.co.th/maps/place/SI-AM+Bag/@13.8038886,100.5489339,14z/data=!4m10!1m2!2m1!1sSI-AM+Bag!3m6!1s0x30e29d2ebf4daea3:0x21b718d39b840996!8m2!3d13.8089204!4d100.5712976!15sCglTSS1BTSBCYWeSAQhiYWdfc2hvcOABAA!16s%2Fg%2F11q88x0k06"
                      target="_blank"
                      className="text-decoration"
                      style={{ marginLeft: "5px" }}
                    >
                      {detail2.franchise_subdistrict}{" "}
                      {detail2.franchise_district} {detail2.franchise_province}
                    </a>
                  </CardTitle>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                ร้าน {detail2.franchise_name} {detail2.franchise_branchname}
              </CardTitle>
              <CardText></CardText>
              <Row>
                <Col sm="5">
                  <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    คุณสมบัติงาน
                  </CardTitle>
                  <CardText>
                    รูปแบบงาน : {detail2.position} <br />
                    จำนวนที่รับ : {detail2.position_required} <br />
                    สถานที่ปฏิบัติงาน : {detail2.franchise_subdistrict}{" "}
                    {detail2.franchise_province}
                    <br />
                    เงินเดือน(บาท) : {detail2.payroll}
                    <br />
                    เวลาทำงาน :{" "}
                    {detail2.franchise_name === undefined
                      ? ""
                      : "สามารถเลือกเป็นกะ"}
                    <br />
                    เวลาทำงานอื่น :{" "}
                    {detail2.franchise_name === undefined ? "" : "ไม่ระบุ"}
                    <br />
                  </CardText>
                  <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    หน้าที่ความรับผิดชอบ
                  </CardTitle>
                  <CardText>{detail2.responsibilities}</CardText>
                  {/* <CardTitle tag="h7" style={{ fontWeight: "bold" }}>
                    คุณสมบัติ
                  </CardTitle> */}
                  {/* <CardText>
                    เพศ : ชาย , หญิง
                    <br />
                    อายุ(ปี) : 25 - 36
                    <br />
                    ระดับการศึกษา : ปวส. - ปริญญาตรี
                    <br />
                    ประสบการณ์(ปี) : 1 - 3<br />
                    อื่นๆ : ยินดีรับนักศึกษาจบใหม่
                    <br />
                    ,ยินดีรับผู้ไม่มีประสบการณ์
                    <br />
                  </CardText> */}
                </Col>
                <Col sm="7" align="center" style={{ marginTop: "30px" }}>
                  <Row>
                    <Col sm="2"></Col>
                    <Col sm="8" align="left">
                      <CardText>
                        <Label style={{ fontWeight: "bold" }}>สถานะ :</Label>{" "}
                        {detail2.register_status}
                        <br />
                        <Label style={{ fontWeight: "bold" }}>
                          สมัครไปเมื่อ :
                        </Label>{" "}
                        {checkDateapply(detail2.register_date)}
                        {/* {moment(detail2.register_date).format("DD/MM/YYYY")} */}
                        {console.log(detail2.register_date)}
                        <br />
                        <Label style={{ fontWeight: "bold" }}>
                          วันนัดสัมภาษณ์ :
                        </Label>{" "}
                        {checkDate(detail2.interview_date)}
                      </CardText>
                    </Col>
                  </Row>
                  <img
                    src={map}
                    style={{
                      width: "80%",
                      borderRadius: "6%",
                    }}
                  />
                </Col>
              </Row>
              {/* <Row>
                            <Col sm="6"></Col>
                            <Col sm="6">
                                <CardText>
                                    สถานะ : รอสัมภาษณ์
                                    <br />
                                    สมัครไปเมื่อ : 28 ก.ค. 2022
                                    <br />
                                    วันนัดสัมภาษณ์ : 30 ก.ค. 2022
                                </CardText>
                            </Col>
                        </Row> */}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>
                ตกลง
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </div>
    )
  );
};

export default DetialPage;
